import { Box, Flex, system, SystemProps } from "flicket-ui";
import { FC, useEffect, useRef, useState } from "react";
import { Editor, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlate,
} from "slate-react";
import styled from "styled-components";
import { Modal } from "~components";
import { useOnClickOutside } from "~hooks";
import { addProtocolToURL } from "~lib/helpers/addProtocolToURL";
import {
  InsertButtonOrLinkOnSuccessOptions,
  InsertModalContent,
  SuggestedLinkType,
} from "./InsertModal";
import { Popover } from "./Link";

export const StyledPopover = styled(Flex)<SystemProps>`
  position: absolute;
  z-index: 5000;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  padding: 12px 16px;
  align-items: center;
  font-size: 14px;

  ${system}
`;

export const withButton = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "button" ? true : isVoid(element);
  };

  return editor;
};

export const insertButton = (
  editor: ReactEditor,
  content: string,
  color: string,
  url?: string,
  suggestedLink?: SuggestedLinkType,
  eventId?: string,
  releaseId?: string
) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  url = addProtocolToURL(url);

  const button = {
    type: "button",
    url,
    content,
    color,
    suggestedLink,
    eventId,
    releaseId,
    children: [{ text: "" }],
  };

  Transforms.insertNodes(editor, button, {
    at: blurSelection,
  });
  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

const editButton = (
  editor: ReactEditor,
  content: string,
  color: string,
  url?: string,
  suggestedLink?: SuggestedLinkType,
  eventId?: string,
  releaseId?: string
) => {
  const blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    console.error("Cannot edit button without selection.");
    return;
  }

  url = addProtocolToURL(url);

  Transforms.setNodes(
    editor,
    {
      url,
      content,
      color,
      suggestedLink,
      eventId,
      releaseId,
    },
    {
      at: blurSelection,
    }
  );

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export const RichTextInsertButton: FC<
  Pick<RenderElementProps, "attributes"> & {
    url?: string;
    content: string;
    color: string;
    suggestedLink?: SuggestedLinkType;
    eventId?: string;
    releaseId?: string;
  }
> = ({
  attributes,
  url,
  children,
  content,
  color,
  suggestedLink,
  eventId,
  releaseId,
}) => {
  if (suggestedLink) {
    url = undefined;
  }

  const selected = useSelected();
  const focused = useFocused();

  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsPopoverOpen(false));

  useEffect(() => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const buttonNodeArr = Array.from(
        Editor.nodes(editor, {
          match: (n) => n.type === "button",
        })
      );
      if (buttonNodeArr.length && selected && focused) {
        setIsPopoverOpen(true);
      }
    } else {
      setIsPopoverOpen(false);
    }
  }, [editor.selection]);

  //  Border based button https://litmus.com/blog/a-guide-to-bulletproof-buttons-in-email-design
  return (
    <>
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tr>
          <td align="center">
            <table cellSpacing="0" cellPadding="0">
              <tr>
                <td>
                  <Box
                    position={"relative"}
                    display="flex"
                    justifyContent={"center"}
                  >
                    <a
                      {...attributes}
                      css={`
                        color: #ffffff;
                        font-weight: bold;
                        border-radius: 5px;
                        background-color: ${color};
                        border-top: 12px solid ${color};
                        border-bottom: 12px solid ${color};
                        border-right: 18px solid ${color};
                        border-left: 18px solid ${color};
                        display: inline-block;
                        text-decoration: none;
                      `}
                    >
                      {children}
                      {content}
                    </a>
                    {isPopoverOpen && (
                      <StyledPopover ref={ref} bottom={"calc(100% + 8px)"}>
                        <Popover
                          editor={editor}
                          url={url}
                          setIsOpen={setIsOpen}
                          color={color}
                        />
                      </StyledPopover>
                    )}
                  </Box>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        <InsertModalContent
          setIsOpen={setIsOpen}
          isEmail
          isEdit
          defaultValues={{
            eventId,
            url,
            content,
            color,
            suggestedLink,
            releaseId,
            type: "button",
          }}
          onSuccess={(options: InsertButtonOrLinkOnSuccessOptions) => {
            const {
              content,
              color,
              url,
              suggestedLink,
              eventId,
              releaseId,
            } = options;
            editButton(
              editor,
              content,
              color,
              url,
              suggestedLink,
              eventId,
              releaseId
            );
          }}
        />
      </Modal>
    </>
  );
};
