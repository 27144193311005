import React, { FC, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers";
import { startOfDay, endOfDay } from "date-fns";
import { Box, Text, PrimaryButton } from "flicket-ui";
import { camelCase } from "lodash";
import { useRouter } from "next/router";
import { stringify } from "qs";
import { useForm, Controller as RHFController } from "react-hook-form";
import { Select, Divider, RawParams, FormSection } from "~components";
import { apiUrl } from "~config";
import { useOrganization, usePermissions, useUser } from "~hooks";
import { OrderChannel, ReportingFilterSource, Role } from "~graphql/sdk";
import { downloadFile, showToast } from "~lib/helpers";
import { Filters, filterOptions } from "./Filters";
import {
  useFilterParams,
  ExcludeProps,
} from "~features/useParams/hooks/useParams";
import { useQuery } from "~hooks/useQuery";
import {
  GetZonesByEventIdDocument,
  TicketTypesDocument,
  UsersDocument,
} from "~graphql/typed-document-nodes";
import {
  dataOptions,
  defaultTypes,
  ExportModalType,
  ExportModalValues,
  Props,
  schema,
  Wrapper,
} from "./config";

export const ExportModalContent: FC<Props> = ({
  close,
  initialParams,
  initialType: initialTypeProp,
}) => {
  const router = useRouter();
  const { organization } = useOrganization();
  const { hasPermissions } = usePermissions();
  const [initialType, setInitialType] = useState(
    initialTypeProp ||
      defaultTypes?.find(({ pathname }) => pathname === router.pathname)
        ?.value ||
      "ticket"
  );

  const { isSalesOutlet, user, isEventManager } = useUser();

  const isRestricted = isSalesOutlet || isEventManager;

  useEffect(() => {
    if (initialTypeProp) {
      setInitialType(initialTypeProp);
    }
  }, [initialTypeProp]);

  const {
    control,
    handleSubmit,
    errors,
    watch,
    register,
  } = useForm<ExportModalValues>({
    defaultValues: {
      type: initialType,
    },
    resolver: yupResolver(schema),
  });

  const type = watch("type");

  register("type");

  const { data: sellersData } = useQuery(
    type === "order" && !isSalesOutlet && UsersDocument,
    {
      where: {
        roles: [Role.Sales, Role.Admin, Role.SalesOutlet],
      },
    }
  );

  const sellers = sellersData?.users?.edges?.map(({ node }) => node);

  const sellersOptions = sellers?.map(({ fullName, id }) => ({
    label: fullName,
    value: id,
  }));

  const exclude: ExcludeProps<ExportModalType> = {
    addons: { release: true },
    cartAbandonment: { channel: true },
    ticket: { source: true },
    membership: { source: true, release: true },
    scans: { source: true, channel: true, dates: true },
    user: { channel: true },
    registration: { release: true, source: true, channel: true, dates: true },
  };

  const overwriteExcludeTemp = {
    addons: { ...exclude.addons },
    ticket: { ...exclude.ticket },
    membership: { ...exclude.membership },
    cartAbandonment: { ...exclude.cartAbandonment },
    scans: { ...exclude.scans },
    user: { ...exclude.user },
    registration: { ...exclude.registration },
  };

  const paramMethods = useFilterParams({
    initialParams: {
      dates: null,
      startDate: null,
      endDate: null,
      ...initialParams,
      excludeAllReleases: true,
    },
    ...(type === "cartAbandonment" && { exclude: exclude.cartAbandonment }),
    ...(type === "scans" && { exclude: { dates: true } }),
    ...(type === "registration" && { exclude: { dates: true } }),
    ...(["order", "transactions"].includes(type) && {
      enablePointSource: true,
    }),
    ...(isRestricted && {
      excludeAllReleases: true,
      excludeAllEvents: true,
    }),
    isSalesOutlet,
  });

  const showZonesAndTicketTypes =
    type === "ticket" &&
    paramMethods.params.source === ReportingFilterSource.Event &&
    !!paramMethods.params.sourceId;

  const { data: zonesData } = useQuery(
    showZonesAndTicketTypes && GetZonesByEventIdDocument,
    {
      eventId: paramMethods.params.sourceId,
    }
  );

  const zonesOptions = [
    {
      label: "All zones",
      value: undefined,
    },
    ...(zonesData?.getZonesByEventId?.map(({ name, id }) => ({
      label: name,
      value: id,
    })) || []),
  ];

  const { data: ticketTypesData } = useQuery(
    showZonesAndTicketTypes && TicketTypesDocument,
    {
      eventId: paramMethods.params.sourceId,
    }
  );

  const ticketTypeOptions = [
    {
      label: "All ticket types",
      value: undefined,
    },
    ...(ticketTypesData?.ticketTypes?.map(({ name, id }) => ({
      label: name,
      value: id,
    })) || []),
  ];

  useEffect(() => {
    const { params, setParams } = paramMethods;

    if (["addons", "ticket", "scans", "registration"].includes(type)) {
      setParams({
        ...params,
        source: ReportingFilterSource.Event,
      });
    }

    if (["membership"].includes(type)) {
      setParams({
        ...params,
        source: ReportingFilterSource.Membership,
      });
    }

    if (isRestricted) {
      setParams({
        ...params,
        source: ReportingFilterSource.Event,
        channel: OrderChannel.BackOffice,
      });
    }
  }, [type, isRestricted]);

  const onSubmit = (values: ExportModalValues) => {
    if (isSalesOutlet && paramMethods.releaseOptions.length === 0) {
      showToast(
        "An outlet release needs to be created for this event first.",
        "error"
      );
      return;
    }

    const dataOption = dataOptions?.filter((option) => {
      return option.value == values.type;
    });

    const { amount, status, ...filters } = values?.filters || {};
    const { params } = paramMethods;
    const {
      dates,
      startDate,
      endDate,
      channel,
      status: paramsStatus,
      ...rest
    } = params;

    const query = stringify({
      "flicket-org-id": organization?.id,
      ...(values.type === "membership" && { type: "membership" }),
      ...(values.type === "ticket" && { type: "single-event" }),
      ...(values.type === "addons" &&
        params.source === ReportingFilterSource.Event && {
          type: "single-event-addon",
        }),
      ...(values.type === "addons" &&
        params.source === ReportingFilterSource.Membership && {
          type: "membership-addon",
        }),

      ...(dates?.[0] && {
        createdAfter: startOfDay(new Date(dates[0])),
      }),
      ...(dates?.[1] && {
        createdBefore: endOfDay(new Date(dates[1])),
      }),
      ...rest,
      ...filters,
      ...(amount?.min && { minAmount: amount.min }),
      ...(amount?.max && { maxAmount: amount.max }),
      ...(channel && { channel: camelCase(channel) }),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ...(status && {
        statuses: (status as string[]).map((word) => word.toLowerCase()),
      }),
      ...(isSalesOutlet && {
        createdById: user.id,
        releaseId: paramMethods.releaseOptions[0].value,
      }),
    });

    const url = `${apiUrl}/export/${dataOption[0].pathname}?${query}`;

    const name = `${
      dataOptions
        ?.filter((o) => hasPermissions(o.permission))
        .find(({ value }) => values.type === value).label
    }_export-${new Date().toString()}.csv`;

    downloadFile(url, name);

    return close();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormSection title="Export data">
        <Wrapper alignItems="center" flexWrap="wrap">
          <Text
            color="N600"
            fontSize={3}
            fontWeight="extraBold"
            flexShrink={0}
            className="label"
          >
            Select data
          </Text>
          {!isRestricted && (
            <Box
              borderLeft="1px"
              borderRight="1px"
              borderColor="N200"
              px="6/4"
              ml="6/4"
            >
              <RHFController
                as={Select}
                control={control}
                name="type"
                options={dataOptions}
                error={errors?.type?.message}
                flexShrink={0}
                flex={1}
              />
            </Box>
          )}

          <RawParams
            {...paramMethods}
            enableDeleteDates
            // @todo replace with exclude[type] again
            exclude={{
              ...overwriteExcludeTemp[type],
              ...(isRestricted && {
                source: true,
                channel: true,
                release: true,
              }),
            }}
          >
            {type === "order" && !isSalesOutlet && (
              <RHFController
                as={Select}
                options={sellersOptions}
                name={`filters.createdById`}
                control={control}
                maxWidth={250}
                placeholder="Select sellers"
              />
            )}
            {showZonesAndTicketTypes && (
              <>
                <RHFController
                  as={Select}
                  options={zonesOptions}
                  name={`filters.zone`}
                  control={control}
                  maxWidth={250}
                  placeholder="Select zone"
                />
                <RHFController
                  as={Select}
                  options={ticketTypeOptions}
                  name={`filters.ticketType`}
                  control={control}
                  maxWidth={250}
                  placeholder="Select ticket type"
                />
              </>
            )}
          </RawParams>
        </Wrapper>

        {filterOptions[type]?.length > 0 && !isSalesOutlet && (
          <>
            <Divider mt="6/4" mb={5} />

            <Filters
              type={type}
              control={control}
              register={register}
              paramMethods={paramMethods}
            />
          </>
        )}
        <Divider />

        <PrimaryButton type="submit" display="block" ml={"auto" as any} px={5}>
          Proceed
        </PrimaryButton>
      </FormSection>
    </Box>
  );
};
