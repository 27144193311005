import { atom, useAtom } from "jotai";
import { ChangeEvent, CSSProperties, useEffect } from "react";
import { Editor, Range, Transforms } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useEditor,
  useFocused,
  useSelected,
  useSlate,
} from "slate-react";
import { useTheme } from "styled-components";
import { ExtendedFile } from "~graphql/sdk";
import { useSDK } from "~hooks";
import { getError, getGCSFile, handlePromise, showToast } from "~lib";
import { Icon } from "../Icon";
import { Button } from "./components";
import { Text } from "flicket-ui";
import { HistoryEditor } from "slate-history";

export const richTextImageAtom = atom(false);

const insertImage = (editor: ReactEditor, url: string, isBanner: boolean) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection || isBanner) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  const image = {
    type: "image",
    url,
    size: isBanner ? "full" : "large",
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, image, {
    at: blurSelection,
  });

  // add 1 to focus on the image
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };

  if (isBanner) {
    const node = editor.children[blurSelection.anchor.path[0]];
    if (node.type === "paragraph" && node.children[0].text === "") {
      Transforms.removeNodes(editor, {
        at: blurSelection,
      });
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        {
          at: blurSelection,
        }
      );
    }
    editor.selection = blurSelection;
  } else {
    editor.selection = newRange;
  }
  // add a paragraph so the user can edit access the next line in the editor
  Transforms.insertNodes(editor, [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  ReactEditor.focus(editor);
};

export const DeleteImageButton = () => {
  const editor = useEditor();

  return (
    <Button
      onClick={() => {
        editor.deleteBackward("block");
      }}
    >
      <Icon icon="trash" fontSize={20 as any} mr={"1/4"} />

      <Text fontSize={"13px" as any} fontWeight="demiBold">
        Delete image
      </Text>
    </Button>
  );
};

export const fileSelectHandler = async (
  event: ChangeEvent<HTMLInputElement>,
  editor: ReactEditor,
  sdk: ReturnType<typeof useSDK>,
  isBanner: boolean
) => {
  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    const [error, data] = await handlePromise(async () =>
      sdk.uploadImage({
        file: {
          file: newFile,
        },
      })
    );

    if (error) {
      showToast(getError(error, "graphQL"), "error");
      return;
    }

    const url = getGCSFile(data.uploadImage as ExtendedFile);

    insertImage(editor, url, isBanner);
  }
};

export const withImages = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  return editor;
};

export const ImageElement = ({
  attributes,
  children,
  element,
  style,
}: RenderElementProps & { style?: CSSProperties }) => {
  const selected = useSelected();
  const focused = useFocused();

  // useslate will rerender whenever changes occur, while useEditor will not
  const editor = useSlate();

  const [, setIsImageSelected] = useAtom(richTextImageAtom);

  useEffect(() => {
    return () => {
      setIsImageSelected(false);
    };
  }, []);

  useEffect(() => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const node = editor.children[selection.anchor.path[0]];
      if (node?.type === "image" && node?.size !== "full") {
        setIsImageSelected(true);
      } else {
        setIsImageSelected(false);
      }
    } else {
      setIsImageSelected(false);
    }
  }, [editor.selection]);

  return (
    <div {...attributes} style={style}>
      {/* children must be passed even not used https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
      <div contentEditable={false}>
        <img
          style={{
            minWidth: "100%",
            borderRadius: element.size === "full" ? 0 : "4px",
            boxShadow:
              selected && focused
                ? `0 0 0 2px ${useTheme().colors.P300}`
                : "none",
            marginBottom: element.size === "full" ? "45px" : "0",
          }}
          src={element.url as string}
          alt="/static/no-image.jpg"
        />
      </div>
    </div>
  );
};
