import { pick } from "@styled-system/props";
import { Box, Flex, SystemProps, Text } from "flicket-ui";
import Link from "next/link";
import { FC, ReactNode, useRef } from "react";
import { useInView } from "react-intersection-observer";

import { Container, Icon, Navbar, Seo, Status } from "~components";
import { useSuperAdmin } from "~hooks";

import { upperFirst } from "lodash";
import { Dropdown } from "./Dropdown";
import { StickyHeader } from "./StickyHeader";

interface HeaderProps extends SystemProps {
  title: string;
  removeAccountDropdown?: boolean;
}

interface LayoutProps extends HeaderProps {
  description?: string;
  header?: ReactNode;
  stickyHeader?: ReactNode;
  children: ReactNode;
  backTo?: {
    label: string;
    route: string;
    as?: string;
  };
  loading?: boolean;
  error?: string;
  textLeft?: ReactNode;
  removeNav?: boolean;
}

const Header: FC<HeaderProps> = ({
  title,
  children,
  removeAccountDropdown,
}) => (
  <Flex
    alignItems="center"
    pt={{ _: 2, xs: 44 as any }}
    pb={{ _: 2, xs: "20px" as any }}
    width={1}
    borderBottom={{ xs: "1px" }}
    borderColor={{ xs: "N300" }}
    flexWrap="wrap"
  >
    <Flex
      flex={1}
      alignItems={["flex-start", "flex-start", "center"]}
      flexDir={["column", "column", "row"]}
    >
      <Text
        mr={2}
        fontSize={7}
        lineHeight="normal"
        fontWeight="heavy"
        mb={[2, 2, 0]}
        color="N800"
      >
        {upperFirst(title)}
      </Text>
      <Flex flex={1} justifyContent={"space-between"} w={"100%"}>
        {children}

        {!removeAccountDropdown && <Dropdown marginLeft={"auto" as any} />}
      </Flex>
    </Flex>
  </Flex>
);

export const Layout = (props: LayoutProps) => {
  const {
    title,
    description,
    header,
    stickyHeader,
    backTo,
    children,
    loading = false,
    error = null,
    removeAccountDropdown = false,
    removeNav = false,
    textLeft,
  } = props;

  const rootRef = useRef(null);
  const { isSuperAdmin, isSuperAdminDomain } = useSuperAdmin();
  const { ref, inView } = useInView({
    initialInView: true,
    root: rootRef?.current,
  });

  return (
    <>
      <Seo title={title} description={description} />

      <Box
        className="layout"
        display={"flex"}
        flexDirection="column"
        pl={{ _: 0, xs: removeNav ? 0 : (96 as any) }}
        py={0}
        width="100vw"
        height={{
          _: "calc(100vh - 60px)",
          xs:
            isSuperAdmin && !isSuperAdminDomain
              ? "calc(100vh - 40px)"
              : "100vh",
        }}
        bg="N100"
        overflowY="auto"
        position="relative"
        // @xpect-error TODO: add global type definition for styled-system
        {...pick(props)}
        ref={rootRef}
      >
        {!removeNav && <Navbar />}

        <Status loading={loading} error={error} backTo={backTo} showBackButton>
          <Container>
            <Flex width={1} ref={ref}>
              <Header
                removeAccountDropdown={removeAccountDropdown}
                title={title}
              >
                {header}
              </Header>
            </Flex>
          </Container>

          {stickyHeader && (
            <StickyHeader className={!inView ? "stuck" : ""} pt={2} pb={3}>
              <Container alignItems="center" justifyContent="space-between">
                {!inView ? (
                  <>
                    <Text
                      mr={2}
                      fontSize={7}
                      lineHeight="normal"
                      fontWeight="heavy"
                      color="N800"
                    >
                      {title}
                    </Text>
                    {textLeft ? (
                      <Box borderLeft="1px" borderColor="N200" ml={2} pl={2}>
                        {textLeft}
                      </Box>
                    ) : null}
                  </>
                ) : (
                  <>
                    {backTo && (
                      <Link href={backTo.route} as={backTo.as} passHref>
                        <Text
                          color="N400"
                          variant="extraBold.L"
                          as="a"
                          alignItems="center"
                          display="flex"
                        >
                          <Icon icon="chevron-left" mr={1} fontSize={6} />
                          {backTo.label}
                        </Text>
                      </Link>
                    )}
                    {textLeft}
                  </>
                )}

                <Flex ml={"auto" as any} alignItems="center">
                  {stickyHeader}
                </Flex>
              </Container>
            </StickyHeader>
          )}

          <Container
            alignItems="flex-start"
            flexDirection="column"
            pt={stickyHeader ? 0 : { _: 3, xs: 5 }}
            pb={{ _: 3, xs: 5 }}
            flex={1}
            px={{ _: "6/4", md: 10 }}
          >
            {children}
          </Container>
        </Status>
      </Box>
    </>
  );
};
