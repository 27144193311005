import React, {
  useRef,
  MouseEvent,
  FC,
  CSSProperties,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { Box, TransparentButton } from "flicket-ui";
import isUrl from "is-url";
import { Transforms, Editor, Range } from "slate";
import {
  useSlate,
  ReactEditor,
  RenderElementProps,
  useSelected,
  useFocused,
} from "slate-react";
import styled, { useTheme } from "styled-components";
import { Icon, Modal } from "~components";
import { Button } from "./components";
import { HistoryEditor } from "slate-history";
import { InsertModalContent, SuggestedLinkType } from "./InsertModal";
import { useOnClickOutside } from "~hooks";
import { StyledPopover } from "./InsertButton";
import { addProtocolToURL } from "~lib/helpers/addProtocolToURL";
import { createPortal } from "react-dom";

export const withLinks = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { insertData, insertText, isInline, isVoid } = editor;
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, {
        url: text,
        at: blurSelection,
        content: text,
      });
    } else {
      insertText(text);
    }
  };

  editor.isVoid = (element) => {
    return element.type === "link" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, {
        url: text,
        at: blurSelection,
        content: text,
      });
    } else {
      insertData(data);
    }
  };

  return editor;
};

type InsertAndEditLinkOptions = {
  url?: string;
  content: string;
  suggestedLink?: SuggestedLinkType;
  eventId?: string;
  releaseId?: string;
};

export const insertLink = (
  editor: ReactEditor,
  options: InsertAndEditLinkOptions
) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const { content, suggestedLink, url, eventId, releaseId } = options;
  if (blurSelection) {
    wrapLink(editor, {
      url,
      at: blurSelection,
      content,
      suggestedLink,
      eventId,
      releaseId,
    });
    // use the start of the link as the new selection
    // use the end of the link as offset will cause an error (not sure why)
    const minOffset = Math.min(
      blurSelection.anchor.offset,
      blurSelection.focus.offset
    );
    const newRange: Range = {
      anchor: { offset: minOffset, path: blurSelection.anchor.path },
      focus: { offset: minOffset, path: blurSelection.anchor.path },
    };
    editor.selection = newRange;
    ReactEditor.focus(editor);
  }
};

const editLink = (editor: ReactEditor, options: InsertAndEditLinkOptions) => {
  const blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    console.error("Cannot edit link without selection.");
    return;
  }

  const { content, suggestedLink, url, eventId, releaseId } = options;

  let protocalURL = url;

  if (url) {
    protocalURL = addProtocolToURL(url);
  }

  Transforms.setNodes(
    editor,
    {
      url: protocalURL,
      suggestedLink,
      content,
      eventId,
      releaseId,
    },
    {
      at: blurSelection,
      match: (n) => n.type === "link",
      mode: "lowest",
    }
  );

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

const isLinkActive = (editor: ReactEditor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === "link" });
  return !!link;
};

const unwrapLink = (editor: ReactEditor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === "link" });
};

export const removeLink = (editor: ReactEditor) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }
};

type WrapLinkOptions = {
  url?: string;
  at: Range;
  content: string;
  suggestedLink?: SuggestedLinkType;
  eventId?: string;
  releaseId?: string;
};

const wrapLink = (editor: ReactEditor, options: WrapLinkOptions) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { at, content, suggestedLink, url, eventId, releaseId } = options;
  let protocalURL = url;

  const isCollapsed = Range.isCollapsed(at);

  if (url) {
    protocalURL = addProtocolToURL(url);
  }

  const link = {
    type: "link",
    url: protocalURL,
    content,
    suggestedLink,
    eventId,
    releaseId,
    children: [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link, { at });
  } else {
    Transforms.wrapNodes(editor, link, { split: true, at });
    Transforms.collapse(editor, { edge: "end" });
  }
};

// this is used by the non-email editor
export const LinkButton = () => {
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedText, setSelectedText] = useState<string>(undefined);

  useEffect(() => {
    if (isOpen) {
      const selection = editor.selection;
      if (selection) {
        const isCollapsed = Range.isCollapsed(selection);
        if (!isCollapsed) {
          setSelectedText(Editor.string(editor, selection));
        }
      }
    } else {
      setSelectedText(undefined);
    }
  }, [isOpen]);

  return (
    <>
      <Button
        active={isLinkActive(editor)}
        onMouseDown={(event) => {
          event.preventDefault();
          setIsOpen(true);
        }}
      >
        <Icon icon="link" />
      </Button>
      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        <InsertModalContent
          defaultValues={{
            content: selectedText,
          }}
          setIsOpen={setIsOpen}
          onSuccess={({ url, content, suggestedLink, eventId, releaseId }) => {
            insertLink(editor, {
              url,
              content,
              suggestedLink,
              eventId,
              releaseId,
            });
          }}
          isEmail={false}
        />
      </Modal>
    </>
  );
};

export const Popover = ({
  url,
  editor,
  setIsOpen,
  color,
}: {
  url?: string;
  editor: ReactEditor;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  color?: string;
}) => {
  const theme = useTheme();
  if (!color) {
    color = "#3869d4";
  }
  return (
    <>
      {url && (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          contentEditable={false}
          css={`
            color: ${color};
            text-decoration: underline;
            margin-right: ${theme.space[1]}px;
            max-width: 200px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {url}
        </a>
      )}

      <TransparentButton
        pl={1}
        mr={1}
        onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setIsOpen(true);
        }}
        borderRadius={"none"}
        borderLeft={!!url && `1px solid ${theme.colors.N200}`}
      >
        <Icon fontSize={4} icon="broadcast-pencil" />
      </TransparentButton>

      <TransparentButton
        pl={1}
        mr={1}
        onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          editor.deleteBackward("block");
        }}
        borderRadius={"none"}
        borderLeft={`1px solid ${theme.colors.N200}`}
      >
        <Icon fontSize={4} icon="trash" />
      </TransparentButton>
    </>
  );
};

const StyledLink = styled.a<{ href: string }>`
  position: relative;
  color: #3869d4;
  text-decoration: underline;
`;

const POPOVER_HEIGHT = 45;
const MAX_LINK_WIDTH = 200;

export const LinkItem: FC<
  Pick<RenderElementProps, "attributes"> & {
    url: string;
    content: string;
    style?: CSSProperties;
    isEmail: boolean;
    suggestedLink?: SuggestedLinkType;
    eventId?: string;
    releaseId?: string;
  }
> = ({
  attributes,
  url,
  children,
  style,
  content,
  isEmail,
  suggestedLink,
  eventId,
  releaseId,
}) => {
  const selected = useSelected();
  const focused = useFocused();

  const editor = useSlate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsPopoverOpen(false));

  useEffect(() => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const buttonNodeArr = Array.from(
        Editor.nodes(editor, {
          match: (n) => n.type === "link",
        })
      );
      if (buttonNodeArr.length && selected && focused) {
        setIsPopoverOpen(true);
      }
    } else {
      setIsPopoverOpen(false);
    }
  }, [editor.selection]);

  const closestEditor = containerRef.current?.closest(".editor-wrapper");
  const popoverTop = containerRef.current?.offsetTop - POPOVER_HEIGHT;
  // 6 is the approximate width of the a character
  const urlWidth = Math.min(url.length * 6, MAX_LINK_WIDTH);

  const popoverLeft =
    containerRef.current?.offsetLeft +
    containerRef.current?.clientWidth / 2 -
    // 110 is the width of the popover except url
    (110 + urlWidth) / 2;

  return (
    <Box
      style={style}
      position={"relative"}
      display="inline-flex"
      justifyContent={"center"}
      as="span"
      ref={containerRef}
    >
      <StyledLink {...attributes} href={url} contentEditable={true}>
        {content}
        {children}
      </StyledLink>
      {isPopoverOpen &&
        createPortal(
          <StyledPopover
            ref={ref}
            top={`${popoverTop}px`}
            left={`${popoverLeft}px`}
          >
            <Popover url={url} editor={editor} setIsOpen={setIsOpen} />
          </StyledPopover>,
          closestEditor
        )}
      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        <InsertModalContent
          setIsOpen={setIsOpen}
          isEdit
          defaultValues={{
            content,
            url,
            suggestedLink,
            eventId,
            releaseId,
          }}
          onSuccess={({ url, content, suggestedLink, eventId, releaseId }) => {
            editLink(editor, {
              url,
              content,
              suggestedLink,
              eventId,
              releaseId,
            });
          }}
          isEmail={isEmail}
        />
      </Modal>
    </Box>
  );
};
