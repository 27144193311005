import React from 'react';
import { Input } from 'flicket-ui';
import { get } from 'lodash';
import { Controller as RHFController } from 'react-hook-form';

export const Controller: any = ({ as, render, errors, ...props }) => {
  return (
    // @ts-ignore
    <RHFController
      render={(rawFieldProps) => {
        const fieldProps = {
          ...rawFieldProps,
          ...props,
          isValid: !errors?.[props.name],
          error: get(errors, `${props.name}.message`),
        };
        const Element = as || Input;

        return render ? render(fieldProps) : <Element {...fieldProps} />;
      }}
      as={undefined}
      {...props}
    />
  );
};
