import { isValid as isValidDate } from "date-fns";
import { ParamsProps } from "~features/useParams/hooks/useParams";

import {
  OrderChannel,
  ReportingFinancialSalesBreakdownSource,
} from "~graphql/sdk";

export type QueryParams = {
  source?: string;
  sourceId?: string;
  channel?: string;
  releaseId?: string;
  startDate?: string;
  endDate?: string;
};

export const MAP_QUERY_SOURCE_TO_PARAM = {
  event: ReportingFinancialSalesBreakdownSource.Event,
  membership: ReportingFinancialSalesBreakdownSource.Membership,
};

// Invert the above mapping 👆
export const MAP_PARAM_SOURCE_TO_QUERY = Object.fromEntries(
  Object.entries(MAP_QUERY_SOURCE_TO_PARAM).map(([query, param]) => [
    param,
    query,
  ])
);

export const MAP_QUERY_CHANNEL_TO_PARAM = {
  all: null,
  online: OrderChannel.Online,
  office: OrderChannel.BackOffice,
  pos: OrderChannel.Pos,
};

// Invert the above mapping 👆
export const MAP_PARAM_CHANNEL_TO_QUERY = Object.fromEntries(
  Object.entries(MAP_QUERY_CHANNEL_TO_PARAM).map(([query, param]) => [
    param,
    query,
  ])
);

type DateOrString = string | Date;
const parseDates = (startDate: DateOrString, endDate: DateOrString) => {
  const parsedStartDate = startDate && new Date(startDate);
  const validStartDate = isValidDate(parsedStartDate);
  const parsedEndDate = endDate && new Date(endDate);
  const validEndDate = isValidDate(parsedEndDate);
  return {
    startDate: validStartDate ? parsedStartDate : null,
    endDate: validEndDate ? parsedEndDate : null,
  };
};

export const mapQueryToParams = (
  queryParams: QueryParams
): Partial<ParamsProps> => {
  const {
    source,
    sourceId,
    channel,
    releaseId,
    startDate: maybeStartDate,
    endDate: maybeEndDate,
  } = queryParams;

  const { startDate, endDate } = parseDates(maybeStartDate, maybeEndDate);

  let dates = null;

  if (startDate && endDate) {
    dates = [startDate, endDate];
  }

  const params = {
    channel: MAP_QUERY_CHANNEL_TO_PARAM[channel] ?? null,
    source:
      MAP_QUERY_SOURCE_TO_PARAM[source] ??
      ReportingFinancialSalesBreakdownSource.Event,
    sourceId: sourceId ?? "",
    dates,
    release: releaseId ?? null,
    startDate,
    endDate,
  };

  return params;
};

type ParamsToQuery = Partial<
  Omit<ParamsProps, "startDate" | "endDate" | "dates">
> & {
  dates?: DateOrString[];
  startDate?: DateOrString;
  endDate?: DateOrString;
};

export const mapParamsToQuery = (params: ParamsToQuery): QueryParams => {
  const {
    channel,
    source,
    sourceId,
    dates: maybeDates,
    release,
    startDate: maybeStartDate,
    endDate: maybeEndDate,
  } = params;

  let { startDate, endDate } = parseDates(maybeStartDate, maybeEndDate);

  // If dates exist in the dates array, favour them as the start and end date
  if (maybeDates?.length === 2) {
    const { startDate: datesStart, endDate: datesEnd } = parseDates(
      maybeDates[0],
      maybeDates[1]
    );

    startDate = datesStart;
    endDate = datesEnd;
  }

  const query = {
    source: MAP_PARAM_SOURCE_TO_QUERY[source],
    channel: MAP_PARAM_CHANNEL_TO_QUERY[channel],
    sourceId,
    releaseId: release,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
  };

  // remove nullish entries
  const cleanedQuery = Object.fromEntries(
    Object.entries(query).filter(([prop, val]) => val)
  );

  return cleanedQuery;
};
