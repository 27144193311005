import { omit } from "lodash";
import { NextRouter } from "next/router";

/**
 * Helper function to remove one or many query parameters from the URL,
 * without adding to browser history or causing a re-render.
 */
export default function removeQueryParams(
  router: NextRouter,
  params: string[]
) {
  void router.replace(
    {
      pathname: router.pathname,
      query: omit(router.query, params),
    },
    undefined,
    { shallow: true } // No re-render
  );
}
