import React, { useEffect, useState } from "react";
import { Box, Flex, Text } from "flicket-ui";
import { css } from "~lib/css";
import { formatDate } from "~lib/helpers";
import { Avatar } from "./Avatar";
import { HistoryFragment } from "~graphql/sdk";
import { capitalize } from "lodash";

interface HistoryItemProps {
  item: HistoryFragment;
  showHeader?: boolean;
  condensed?: boolean;
}

export const HistoryItem = ({ item, condensed = false }: HistoryItemProps) => {
  const [header, setHeader] = useState("");

  useEffect(() => {
    if (item.refName === "order" && item.order) {
      setHeader(`Order Number: ${item.order.orderNumber}`);
    } else if (item.refName === "scout-user" && item.scoutCommunication) {
      setHeader(`Scout Name: ${item.scoutCommunication.name}`);
    } else if (item.refName === "scan" && item.ticket.ticketNumber) {
      setHeader(`Ticket Number: ${item.ticket.ticketNumber}`);
    }
  }, [item.refName, item.order, item.scoutCommunication]);

  return (
    <Box
      borderRadius="sm"
      bg={"#F3F3F8" as any}
      p={2}
      my={condensed ? undefined : 1}
    >
      <React.Fragment>
        <Flex
          css={css({ "&:not(:last-of-type)": { mb: 4 } })}
          alignItems="center"
        >
          {!condensed && (
            <Avatar user={item.user} bg={"#D3682C" as any} mr={2} />
          )}
          <Flex flexDir="column">
            {!condensed ? (
              <>
                <Text mx={2} fontWeight="heavy">
                  {`Type: ${
                    item.refName === "scout-user"
                      ? "Scout"
                      : capitalize(item.refName)
                  }`}
                </Text>
                <Text mx={2} fontWeight="heavy">
                  {header}
                </Text>
              </>
            ) : null}
            <Text mx={2}>{item.description}</Text>
          </Flex>
          <Text ml={"auto" as any} color="N500" minW={"150px" as any}>
            {formatDate(item.createdAt)} @{" "}
            {formatDate(item?.createdAt, "kk:mm")}
          </Text>
        </Flex>
      </React.Fragment>
    </Box>
  );
};
