import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from "@reach/dialog";
import { omit, pick } from "@styled-system/props";
import { Box, system, SystemProps, Text } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { noop } from "lodash";
import { createContext, ReactNode } from "react";
import styled from "styled-components";

import { Divider, Icon } from "~components";
import {
  modalBackdropMotion,
  modalContentMotion,
} from "~lib/helpers/animation/modal";

const MOBILE_BREAKPOINT = "sm";

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDialog = motion(ReachDialogContent);

export const ModalContext = createContext<{
  close?: (...args: unknown[]) => unknown;
}>({ close: undefined });

const DialogOverlay = styled(MotionOverlay)<{
  $alignModalTop: boolean;
}>`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: ${(p) => p.theme.zIndices.modal};
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    align-items: ${(p) => (p.$alignModalTop ? " flex-start" : "center")};
    padding: ${(p) => p.theme.space[4]}px;
  }
`;

const DialogContent = styled(MotionDialog)<SystemProps>`
  padding: 0;
  width: 100%;
  margin: 0;
  position: relative;
  background: transparent;
  max-height: 100vh;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    width: 774px;
  }

  && {
    ${system}
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    svg {
      color: ${(p) => p.theme.colors.N800};
    }
  }
`;

export const InHeaderCloseButton = styled.button`
  transition: color 0.2s;

  &:hover {
    svg {
      color: ${(p) => p.theme.colors.N800};
    }
  }
`;

const ContentWrapper = styled.div<SystemProps & { $small?: boolean }>`
  position: relative;
  background: ${(p) => p.theme.colors.white};
  outline: none;
  width: 100%;
  padding: ${(p) => p.theme.space[3]}px;
  overflow-y: auto;
  height: 100dvh;

  ${(p) => (p.$small ? `padding: ${p.theme.space[3]}px;` : "")}

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    margin: 64px 0;
    border-radius: ${(p) => p.theme.radii.sm};
    box-shadow: ${(p) => p.theme.shadows.sm};
    max-height: calc(100vh - 160px);
    padding: ${(p) => p.theme.space[5]}px;
    height: auto;
  }

  && {
    ${system}
  }
`;

type ModalProps = SystemProps<{
  isOpen: boolean;
  close: (any) => void;
  ariaLabel?: string;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  hasDivider?: boolean;
  small?: boolean;
  alignModalTop?: boolean;
  dialogContentProps?: SystemProps;
  modalBaseProps?: SystemProps;
  clickOutsideToClose?: boolean;
}>;

export const ModalBaseWrapper = ({
  children,
  ariaLabel,
  isOpen,
  close,
  small = false,
  alignModalTop = false,
  dialogContentProps = {},
  ...props
}: ModalProps) => (
  <AnimatePresence>
    {isOpen && (
      <DialogOverlay
        {...modalBackdropMotion}
        onDismiss={close}
        $alignModalTop={alignModalTop}
      >
        <DialogContent
          {...modalContentMotion}
          aria-label={ariaLabel || "modal"}
          width={small ? "470px" : undefined}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          {...pick(dialogContentProps)}
        >
          <ContentWrapper
            id="modal-content-wrapper"
            $small={small}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            {...pick(props)}
          >
            {children}
          </ContentWrapper>
        </DialogContent>
      </DialogOverlay>
    )}
  </AnimatePresence>
);

export const ModalBase = ({
  children,
  ariaLabel,
  isOpen,
  close,
  clickOutsideToClose = false,
  header,
  small,
  ...props
}: ModalProps) => (
  <ModalContext.Provider value={{ close }}>
    <ModalBaseWrapper
      close={clickOutsideToClose ? close : noop}
      isOpen={isOpen}
      ariaLabel={ariaLabel}
      small={small}
      {...props}
    >
      <Box
        d="flex"
        flexDirection={"row-reverse"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <CloseButton type="button" onClick={close}>
          <Icon icon="close" fontSize={6} />
        </CloseButton>
        {header && (
          <Text fontWeight="heavy" fontSize={small ? 5 : 6}>
            {header}
          </Text>
        )}
      </Box>
      {children}
    </ModalBaseWrapper>
  </ModalContext.Provider>
);

export const Modal = ({
  children,
  footer,
  hasDivider,
  small,
  modalBaseProps,
  ...props
}: ModalProps) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    <ModalBase small={small} {...omit(props)} {...modalBaseProps}>
      {hasDivider && <Divider mt={2} mb={0} />}

      <Box
        overflowY="auto"
        pt={small ? 5 : 4}
        pb={small ? 5 : footer ? 9 : 0}
        fontSize={4}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        {...pick(props)}
      >
        {children}
      </Box>

      {footer}
    </ModalBase>
  );
};
