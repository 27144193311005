import React, { useState, useMemo } from "react";

import { Flex, Stack, PrimaryButton, SecondaryButton } from "flicket-ui";
import { orderBy } from "lodash";

import { useSeatedMultibuy, useNonSeatedMultiBuy, useSDK } from "~hooks";
import { getError, handlePromise, showToast } from "~lib";
import {
  EventQuery,
  LineItemAction,
  OrderQuery,
  VenueSeating,
} from "~graphql/sdk";

import { EditListItem } from "./EditListItem";
import useSWR, { KeyedMutator } from "swr";

type EditModalProps = {
  order: OrderQuery["order"];
  mutate: KeyedMutator<OrderQuery["order"]>;
  setIsOpen: (isOpen: boolean) => void;
};

export type EditModalEventTicketType = EventQuery["event"]["ticketTypes"][number];
export type EditModalTicketType =
  | OrderQuery["order"]["event"]["ticketTypes"][number]
  | OrderQuery["order"]["membership"]["types"][number];
export type EditModalLineItem = OrderQuery["order"]["lineItems"]["edges"][number]["node"];
export type EditModalZone = Partial<
  Omit<
    EventQuery["event"]["venue"]["zones"][number] & {
      ticketTypes: EditModalEventTicketType[];
    } & OrderQuery["order"]["membership"]["membershipZones"][number],
    "__typename"
  >
>;

export const EditModalContent = ({
  order,
  mutate,
  setIsOpen,
}: EditModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [releaseLineItems, setReleaseLineItems] = useState<EditModalLineItem[]>(
    []
  );
  const [updateLineItems, setUpdateLineItems] = useState<EditModalLineItem[]>(
    []
  );
  const sdk = useSDK();

  const { data: eventData } = useSWR("eventsSelect", async () =>
    sdk.event({ id: order.event.id.toString() })
  );

  const zones: EditModalZone[] = order?.event
    ? eventData?.event.venue.zones.map((zone) => ({
        ...zone,
        ticketTypes: eventData?.event.ticketTypes,
      }))
    : order?.membership?.membershipZones.map((zone) => ({
        ...zone,
        ...zone.zone,
      }));
  const allTicketTypes: EditModalTicketType[] =
    order?.event?.ticketTypes ?? order?.membership?.types;
  const originalItems = order?.lineItems?.edges?.map(({ node }) => node) || [];
  const newItems = useMemo(
    () => [
      ...(originalItems?.filter(
        (original) =>
          !updateLineItems?.find((updated) => updated.id === original.id)
      ) ?? []),
      ...updateLineItems,
    ],
    [updateLineItems, order?.id]
  );

  const multibuy =
    order?.membership?.multiBuyPromotions ?? order?.event?.multiBuyPromotions;

  const { activePromotions: seatedPromotions } = useSeatedMultibuy(
    multibuy,
    newItems
  );
  const { activePromotions: promotions } = useNonSeatedMultiBuy(
    multibuy,
    newItems
  );

  const activePromotions =
    (order?.event
      ? order?.event?.venue?.seating
      : order?.membership
      ? order?.membership?.venue?.seating
      : undefined) === VenueSeating.NonSeated
      ? promotions
      : seatedPromotions;

  const toggleReleaseLineItem = (lineItem: EditModalLineItem) => {
    const foundLineItemRelease = releaseLineItems?.find(
      (l) => l.id === lineItem.id
    );

    if (foundLineItemRelease) {
      return setReleaseLineItems([
        ...releaseLineItems.filter((l) => l.id !== lineItem.id),
      ]);
    }

    return setReleaseLineItems([...releaseLineItems, lineItem]);
  };

  const setUpdateLineItem = (
    lineItem: EditModalLineItem,
    update: Partial<EditModalLineItem>
  ) =>
    setUpdateLineItems([
      ...updateLineItems.filter((l) => l.id !== lineItem.id),
      { ...lineItem, ...update },
    ]);

  const handleUpdate = async () => {
    const lineItemActions = [
      ...releaseLineItems.map((li: EditModalLineItem) => ({
        id: li.id,
        action: LineItemAction.Delete,
      })),
      ...updateLineItems.map((li: EditModalLineItem) => ({
        id: li.id,
        ticketOrMembershipTypeId: li?.ticketType?.id ?? li?.membershipType?.id,
        action: LineItemAction.Update,
      })),
    ];

    const payload = {
      lineItemActions,
      ...(activePromotions[0]?.promotion?.id && {
        multiBuyId: activePromotions[0]?.promotion?.id,
      }),
    };

    setIsSubmitting(true);

    const [error] = await handlePromise(async () =>
      sdk.updateHoldOrder({ id: order?.id, input: payload })
    );

    if (error) {
      showToast(getError(error, "graphQL"), "error");
      return setIsSubmitting(false);
    }

    setIsOpen(false);
    setIsSubmitting(false);

    return mutate();
  };

  return (
    <>
      <Stack flexDir="column" space={1} pb={9}>
        {orderBy(
          order?.lineItems?.edges,
          ["node.type", "node.name"],
          ["desc", "asc"]
        ).map(({ node }) => {
          const updateItem = updateLineItems.find(({ id }) => id === node.id);
          const isMultiBuy = (activePromotions as any[]).find((promo) => {
            if (!updateItem) {
              return (
                promo.id === node.id ||
                node?.ticketType?.id === promo?.getType?.id ||
                node?.membershipType?.id === promo?.getType?.id
              );
            }

            return (
              updateItem.id === promo.id ||
              updateItem?.ticketType?.id === promo?.getType?.id ||
              updateItem?.membershipType?.id === promo?.getType?.id
            );
          });

          return (
            <EditListItem
              key={node.id}
              currentZone={
                // TODO fix this
                zones?.find((zone) => zone?.name === node?.seatZone)
              }
              lineItem={node}
              onChange={setUpdateLineItem}
              releaseLineItems={releaseLineItems}
              addToRelease={toggleReleaseLineItem}
              allTicketTypes={allTicketTypes}
              isMultiBuy={isMultiBuy}
            />
          );
        })}
      </Stack>
      <Flex justifyContent="flex-end">
        <SecondaryButton onClick={() => setIsOpen(false)}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => void handleUpdate()}
          ml={2}
          isLoading={isSubmitting}
          disabled={
            updateLineItems.length === 0 && releaseLineItems.length === 0
          }
        >
          Confirm
        </PrimaryButton>
      </Flex>
    </>
  );
};
