import { attempt, isError } from "lodash";

type RichTextItem = {
  children: [
    {
      text?: string;
      url?: string;
    }
  ];
};

export function validateRichText(value): boolean {
  const parsedContent = attempt(JSON.parse, value) as RichTextItem[] | Error;

  if (!parsedContent || isError(parsedContent)) return false;

  return parsedContent?.some((item) => {
    return item.children.some((c) => c.text || c.url);
  });
}
