import useSWR from 'swr';
import { useSDK } from '~hooks';

export const useVenues = () => {
  const sdk = useSDK();
  const { data, ...methods } = useSWR(
    ['venues'],
    () => sdk.venues().then((res) => res.venues),
    {
      revalidateOnFocus: false,
    },
  );

  return { data, ...methods };
};
