import { hotjar } from "react-hotjar";
import { MeQuery } from "~graphql/sdk";

const login = ({ id, ...user }: MeQuery["me"]) => {
  if (hotjar.initialized()) {
    hotjar.identify(id, user);
  }
};

const logout = () => {
  if (hotjar.initialized()) {
    hotjar.identify(null, {});
  }
};

export default {
  login,
  logout,
};
