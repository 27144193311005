import { Transforms, Range } from "slate";
import { ReactEditor } from "slate-react";

export const insertSummary = (editor: ReactEditor, summaryName: string) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };
  Transforms.insertNodes(
    editor,
    {
      type: "summary",
      content: summaryName,
      children: [{ text: "" }],
    },
    { at: blurSelection }
  );
  Transforms.insertNodes(
    editor,
    { type: "paragraph", children: [{ text: "" }] },
    { at: newRange }
  );
};
