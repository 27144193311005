/**
 * utility helper to download a file by REST API, xlsx files will use filename on the backend, not this name
 *
 * @param   {string}  href  link where the file can be fetched
 * @param   {string}  name  name of the file (should include an extension)
 *
 * @return  {void}        void (button click)
 */
export const downloadFile = (href: string, name: string): void => {
  const anchor = document.createElement("a");

  anchor.href = href;
  anchor.target = "_blank";
  anchor.download = name;

  return anchor.click();
};
