import { useRouter } from "next/router";
import useSWR from "swr";
import { EventQuery } from "~graphql/sdk";

import { useSDK } from "~hooks";
import useEventMutations from "./useEventMutations";

export const NEW_EVENT_ID = "new";

export type Event = EventQuery["event"];

export const useEvent = () => {
  const sdk = useSDK();
  const { query } = useRouter();
  const id = query?.id as string;

  const { data: event, error, mutate, ...methods } = useSWR(
    id && id !== NEW_EVENT_ID ? ["event", id] : null,
    async (_, id: string) => sdk.event({ id }).then((res) => res.event),
    {
      revalidateOnFocus: false,
    }
  );

  const { data: releases, error: releaseError } = useSWR(
    event ? ["releases", event.id] : null,
    async (_, id: string) =>
      sdk.releases({ event: id }).then((res) => res.releases),
    {
      revalidateOnFocus: false,
    }
  );

  const { createEvent, updateEvent, duplicateEvent } = useEventMutations({
    eventId: id,
    mutate,
  });

  return {
    ...methods,
    mutate,
    error,
    event,
    releases,
    createEvent,
    updateEvent,
    duplicateEvent,
  };
};
