import { useSessionStorage } from "usehooks-ts";
import { useRouter } from "next/router";
import { omit } from "lodash";
import { OrganizationFeatures } from "~lib/features";
import { apiUrl } from "~config";
import removeQueryParams from "~lib/helpers/removeQueryParams";

export const FeatureFlags = Object.values(OrganizationFeatures);
type FeatureFlag = typeof FeatureFlags[number];

export default function useSessionFeatureFlags() {
  const router = useRouter();

  const [value, setValue] = useSessionStorage<OrganizationFeatures[]>(
    "features",
    []
  );

  if (
    router?.query.feature &&
    !value.length &&
    !apiUrl.includes("api.flicket.co.nz")
  ) {
    const featureFlags = Array.isArray(router.query.feature)
      ? router.query.feature
      : [router.query.feature];

    setValue(featureFlags as OrganizationFeatures[]);

    removeQueryParams(router, ["feature"]);
  }

  return value.reduce((acc, name) => ({ ...acc, [name]: true }), {}) as Record<
    FeatureFlag,
    true
  >;
}
