import { Link, PrimaryButton, Text, UnderlineButton } from "flicket-ui";
import hoistNonReactStatics from "hoist-non-react-statics";
import { get, intersection, startsWith } from "lodash";
import Router from "next/router";
import React, { useEffect, useState } from "react";

import { Loader, Seo } from "~components";
import { Layout } from "~components/login";
import { Role } from "~graphql/sdk";
import { useSuperAdmin, useUser } from "~hooks";

import { UserActionTypes } from "~context";
import Error from "~pages/_error";

const getDisplayName = (Component) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  Component.displayName || Component.name || "Component";

const loggedOutPaths = [
  "/login",
  "/forgot-password",
  "/login-link",
  "/authenticate",
];

const doNotRedirectPaths = ["/set-password"];

const superAdminPaths = ["/accounts", "/venues", "/finance"];

export function withAuth(Component: any) {
  const AuthComponent = (props) => {
    const {
      user,
      isLoggedIn,
      hasLoaded,
      logout,
      isTwoFactorAuthenticated,
      dispatch,
    } = useUser();
    const [isRedirected, setIsRedirected] = useState(hasLoaded);
    const [shouldLogOut] = useState(false);
    const { isSuperAdmin, isSuperAdminDomain } = useSuperAdmin();

    useEffect(() => {
      if (hasLoaded) {
        if (doNotRedirectPaths.includes(Router.pathname)) {
          setIsRedirected(true);
          return;
        }

        if (
          isLoggedIn &&
          user.isTwoFactorAuthenticationEnabled &&
          !isTwoFactorAuthenticated
        ) {
          void Router.replace({ pathname: `/authenticate` });
        } else if (isLoggedIn && loggedOutPaths.includes(Router.pathname)) {
          void Router.replace({ pathname: isSuperAdmin ? "/accounts" : "/" });
        } else if (!isLoggedIn && !loggedOutPaths.includes(Router.pathname)) {
          void Router.replace({ pathname: `/login` });
        } else if (
          isLoggedIn &&
          !user.hasPassword &&
          !user.isLoggedInWithLinkedAccount &&
          user.roles.includes(Role.Admin)
        ) {
          void Router.replace({ pathname: `/set-password` });
        } else {
          setIsRedirected(true);
        }
      }
    }, [hasLoaded, user, isLoggedIn, isTwoFactorAuthenticated]);

    if (!isRedirected) {
      return <Loader />;
    }

    if (shouldLogOut) {
      const handleBackToLogin = () => {
        dispatch({ type: UserActionTypes.LOGOUT });
        void Router.push("/login");
      };

      return (
        <>
          <Seo title="Logged out" />
          <Layout minH="100vh">
            <Text
              fontWeight="demiBold"
              textAlign="center"
              fontSize={6}
              mb={{ _: 0, md: 1 }}
            >
              You have been logged out
            </Text>
            <Text textAlign="center" maxWidth="400px"></Text>
            <PrimaryButton onClick={handleBackToLogin}>
              Back to login
            </PrimaryButton>
          </Layout>
        </>
      );
    }

    if (
      isLoggedIn &&
      !get(
        intersection(get(user, "roles", []), [
          Role.Admin,
          Role.Superadmin,
          Role.Sales,
          Role.PosAdmin,
          Role.PosOperator,
          Role.VenueAdmin,
          Role.EventReporting,
          Role.BackOfficeStaff,
          Role.SalesOutlet,
          Role.EventManager,
        ]),
        "length"
      )
    ) {
      if (user?.roles?.includes(Role.Scanner)) {
        return (
          <>
            <Layout minH="100vh">
              <Text
                fontWeight="demiBold"
                textAlign="center"
                fontSize={6}
                mb={{ _: 0, md: 1 }}
              >
                Download the Scanner app
              </Text>
              <Text textAlign="center" maxWidth="400px">
                Click the button below to download the latest version of the
                Scanner app (version 1.0.1) to continue.
              </Text>
              <Link
                href="/static/downloads/flicket-scanner.apk"
                target="_blank"
                w="100%"
                mt={{ _: 2, lg: 3 }}
              >
                <PrimaryButton w="100%">Download Scanner app</PrimaryButton>
              </Link>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <UnderlineButton onClick={logout} mt={3} color="N600">
                Log out
              </UnderlineButton>
            </Layout>
          </>
        );
      }

      return (
        <>
          <Seo title="Unauthorized" />
          <Layout minH="100vh">
            <Text
              fontWeight="demiBold"
              textAlign="center"
              fontSize={6}
              mb={{ _: 0, md: 1 }}
            >
              Unauthorized
            </Text>
            <Text textAlign="center" maxWidth="400px">
              You are not authorized to view this environment. Click the button
              below to go to the customer portal.
            </Text>
            <Link
              href={window.location.href.replace("admin.", "")}
              w="100%"
              mt={{ _: 2, lg: 3 }}
            >
              <PrimaryButton w="100%">Open customer portal</PrimaryButton>
            </Link>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <UnderlineButton onClick={logout} mt={3} color="N600">
              Back to login
            </UnderlineButton>
          </Layout>
        </>
      );
    }

    if (
      isLoggedIn &&
      isSuperAdmin &&
      isSuperAdminDomain &&
      Router.pathname !== "/" &&
      !superAdminPaths.some((path) => startsWith(Router.pathname, path))
    ) {
      return <Error statusCode={404} />;
    }

    if (
      user?.roles?.includes(Role.PosOperator) &&
      user?.roles.length === 1 &&
      Router.pathname === "/"
    ) {
      const posId = localStorage.getItem("pos-id");

      if (posId) {
        void Router.replace(`/pos/[id]`, `/pos/${posId}`);
      } else {
        void logout();
      }

      return <Loader />;
    }

    return <Component {...props} />;
  };

  hoistNonReactStatics(AuthComponent, Component);

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  AuthComponent.displayName = `withAuth(${getDisplayName(Component)})`;

  return AuthComponent;
}
