import React, { FC, ReactElement, useEffect } from "react";
import { Box, Flex, SystemProps, Text, TransparentButton } from "flicket-ui";
import { motion, useCycle } from "framer-motion";
import { Divider } from "../Divider";
import { Icon } from "../Icon";
import { Toggle } from "../Toggle";

interface Props extends SystemProps {
  title: string | ReactElement;
  active?: boolean;
  onActiveChange?: (active: boolean) => void;
  onRemove?: () => void;
}

export const Collapsible: FC<Props> = ({
  title,
  onActiveChange,
  onRemove,
  active,
  children,
  ...props
}) => {
  const [open, toggleOpen] = useCycle(false, true);

  useEffect(() => {
    if (!active) toggleOpen(0);
  }, [active]);

  return (
    <>
      <motion.div animate={open ? "open" : "close"}>
        <Box borderRadius="sm" border="1px" borderColor="N300" {...props}>
          <Flex
            cursor="pointer"
            onClick={() => {
              toggleOpen();
            }}
            alignItems="center"
            p={2}
          >
            <Flex alignItems="center" width={1}>
              {title instanceof Function ? (
                { title }
              ) : (
                <Text
                  fontSize={4}
                  fontWeight="heavy"
                  pr="6/4"
                  mr="6/4"
                  color="N800"
                >
                  {title}
                </Text>
              )}
            </Flex>
            <Flex alignItems="center" ml={"auto" as any}>
              {[
                !!onActiveChange && (
                  <Flex alignItems="center" key="activeToggle">
                    <Text mr={1}>{active ? "Active" : "Inactive"}</Text>
                    {onActiveChange && (
                      <Toggle
                        onChange={() => onActiveChange(!active)}
                        on="success"
                        off="error"
                        checked={active}
                      />
                    )}
                  </Flex>
                ),
                !!onRemove && (
                  <TransparentButton
                    key="removeButton"
                    onClick={onRemove}
                    p={1}
                  >
                    <Icon icon="close" />
                  </TransparentButton>
                ),
                <motion.div
                  variants={{ open: { rotate: 180 }, close: { rotate: 0 } }}
                >
                  <TransparentButton
                    cursor="pointer"
                    onClick={() => {
                      toggleOpen();
                    }}
                    p={1}
                  >
                    <Icon icon="chevron-down" fontSize={5} />
                  </TransparentButton>
                </motion.div>,
              ].reduce((acc: any, curr, i) =>
                curr
                  ? [
                      acc,
                      <Divider
                        key={i}
                        height={24}
                        width={"1px" as any}
                        mx="6/4"
                        mt="3/4"
                        mb={0}
                      />,
                      curr,
                    ]
                  : acc
              )}
            </Flex>
          </Flex>

          <motion.div
            style={{ overflow: "hidden" }}
            variants={{ open: { height: "auto" }, close: { height: 0 } }}
            transition={{ duration: 0.1 }}
          >
            <Box px={2} pb={2}>
              <Divider mt={0} mb={3} />
              <Box>{children}</Box>
            </Box>
          </motion.div>
        </Box>
      </motion.div>
    </>
  );
};
