import { Flex } from "flicket-ui";
import React from "react";
import { OrderQuery } from "~graphql/sdk";
import { useIsMobile } from "~hooks";
import XeroLogo from "./assets/xeroinline.svg";
import { Button } from "../common/Button";

export const XeroInvoiceButton = ({
  order,
}: {
  order?: OrderQuery["order"];
}) => {
  const isMobile = useIsMobile();
  if (!order?.xeroInvoiceId) return null;

  return (
    <a
      href={`https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${order.xeroInvoiceId}`}
      target={"_blank"}
      rel="noopener noreferrer"
    >
      <Button
        maxWidth={"260px"}
        paddingX={"1/4"}
        mr="6/4"
        style={{
          backgroundColor: "rgba(19,181,234,0.9)",
          paddingTop: "3px",
          paddingBottom: "2px",
        }}
        color={"white"}
        borderColor={"transparent"}
      >
        <Flex flexDir={"row"} alignItems="center" paddingX={6}>
          {!isMobile && (
            <div style={{ paddingBottom: "2px" }}>View invoice on&nbsp;</div>
          )}
          <XeroLogo fontSize="37px" />
        </Flex>
      </Button>
    </a>
  );
};
