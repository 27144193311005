import { Text } from "flicket-ui";
import React from "react";
import { Editor, Element, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";

import { Icon } from "~components";
import { IconName } from "../Icon/Icon";
import { Button } from "./components";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const TEXT_ALIGN_TYPES = ["left", "center", "right"];

const IMAGE_SIZES = ["small", "medium", "large", "full"];

const toggleBlock = (editor: ReactEditor, format: string) => {
  const isList = LIST_TYPES.includes(format);
  const isImageSize = IMAGE_SIZES.includes(format);
  const isAlign = TEXT_ALIGN_TYPES.includes(format);

  const isActive = isBlockActive(
    editor,
    format,
    isAlign ? "align" : isImageSize ? "size" : "type"
  );

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(n.type as string) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties: Partial<Element>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else if (isImageSize) {
    newProperties = {
      size: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: ReactEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (
  editor: ReactEditor,
  format: string,
  blockType: "type" | "align" | "size" = "type"
) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n[blockType] === format,
  });

  return !!match;
};

const isMarkActive = (editor: ReactEditor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

type ButtonProps = {
  format: string;
  icon?: IconName;
  text?: string;
};

export const BlockButton = ({ format, icon, text }: ButtonProps) => {
  const editor = useSlate();
  const isImageSize = IMAGE_SIZES.includes(format);
  const isAlign = TEXT_ALIGN_TYPES.includes(format);

  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        isAlign ? "align" : isImageSize ? "size" : "type"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon && <Icon icon={icon} fontSize={"20px" as any} mr={"1/4"} />}
      {text && (
        <Text fontSize={"13px" as any} fontWeight="demiBold">
          {text}
        </Text>
      )}
    </Button>
  );
};

export const MarkButton = ({ format, icon }: ButtonProps) => {
  const editor = useSlate();

  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon icon={icon} fontSize={"20px" as any} mr={"1/4"} />
    </Button>
  );
};
