import { debounce } from "lodash";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ThemeContext } from "styled-components";

const parseBreakpoint = (breakpoint: string) =>
  Number(breakpoint.replace(/\D/g, ""));

export type ContextProps =
  | Record<string, never>
  | Record<
      | "isPhone"
      | "isPhoneUp"
      | "isTabletPortrait"
      | "isTabletPortraitUp"
      | "isTabletPortraitDown"
      | "isTabletLandscape"
      | "isTabletLandscapeUp"
      | "isTabletLandscapeDown"
      | "isDesktop"
      | "isDesktopUp"
      | "isDesktopDown",
      boolean
    >;

const ScreenSizeContext = createContext<ContextProps>({});

interface Props {
  children: ReactNode;
}

/**
 * Inspiration taken from
 * https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
 *
 * Up to date screen size usage taken from
 * https://www.browserstack.com/guide/ideal-screen-sizes-for-responsive-design
 */

/**
 * @typedef {Object} ScreenSizes
 * @property {boolean} isPhone
 * @property {boolean} isPhoneUp
 * @property {boolean} isTabletPortrait
 * @property {boolean} isTabletPortraitUp
 * @property {boolean} isTabletPortraitDown
 * @property {boolean} isTabletLandscape
 * @property {boolean} isTabletLandscapeUp
 * @property {boolean} isTabletLandscapeDown
 * @property {boolean} isDesktop
 * @property {boolean} isDesktopUp
 * @property {boolean} isDesktopDown
 */

/**
 * Function that returns an object with human readable screen sizes, based on our theme breakpoints.
 *
 * Breakpoint reference:
 * xxs = 320px
 * xs  = 640px
 * sm = 768px
 * md = 1024px
 * lg = 1280px
 * xl = 1440px
 *
 * @returns {ScreenSizes}
 */
const ScreenSizeProvider = ({ children }: Props) => {
  const { breakpoints } = useContext(ThemeContext);

  // TODO: Move this to Flicket UI where breakpoints are defined so
  // we don't need to generate this dynamically and it can be available
  // for other applications to use.
  const baseRanges = useMemo(
    () => ({
      isPhone: [0, parseBreakpoint(breakpoints.xs) - 1],
      isTabletPortrait: [
        parseBreakpoint(breakpoints.xs),
        parseBreakpoint(breakpoints.md) - 1,
      ],
      isTabletLandscape: [
        parseBreakpoint(breakpoints.md),
        parseBreakpoint(breakpoints.lg) - 1,
      ],
      isDesktop: [
        parseBreakpoint(breakpoints.lg),
        parseBreakpoint(breakpoints.xl),
      ],
    }),
    []
  );

  const [screenRanges, setScreenRanges] = useState<ContextProps>({});

  const checkBreakPoint = debounce(() => {
    const width = window.innerWidth;
    const rangeValues = Object.entries(baseRanges);

    // Generate default sizes set to `false`
    const sizes = Object.fromEntries(
      rangeValues.map(([key, _]) => [key, false])
    );

    // Determine active ranges
    rangeValues.forEach(([key, [lower, upper]]) => {
      sizes[key] = width >= lower && width <= upper;
      sizes[`${key}Up`] = width >= lower;
      sizes[`${key}Down`] = width <= upper;
    });

    setScreenRanges(sizes as ContextProps);
  }, 100);

  const checkBreakPointMemo = useMemo(() => checkBreakPoint, []);

  useEffect(() => {
    checkBreakPoint();

    window.addEventListener("resize", checkBreakPointMemo);
    return () => {
      checkBreakPoint.cancel();
      window.removeEventListener("resize", checkBreakPointMemo);
    }
  }, []);

  return (
    <ScreenSizeContext.Provider value={screenRanges}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

export { ScreenSizeProvider, ScreenSizeContext };
