import { Editor, Transforms, Range } from "slate";
import { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";

export const insertDivider = (editor: ReactEditor) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };
  const divider = { type: "divider", children: [{ text: "" }] };
  const newLine = { type: "paragraph", children: [{ text: "" }] };
  Transforms.insertNodes(editor, divider, { at: blurSelection });
  Transforms.insertNodes(editor, newLine, { at: newRange });
};

export const withDivider = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) => {
    return element.type === "divider" ? true : isVoid(element);
  };
  return editor;
};
