import { useContext } from "react";
import { ScreenSizeContext } from "~context/ScreenSizeContext";

/**
 * See Context function for desciption
 */
const useScreenSize = () => {
  return useContext(ScreenSizeContext);
};

export default useScreenSize;
