import React, { FC, Fragment, useMemo, useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  PrimaryButton,
  Text,
  Flex,
  TransparentButton,
  Spinner,
  Checkbox,
} from "flicket-ui";
import { find } from "lodash";
import qz from "qz-tray";

import { Divider, ModalBase, Icon } from "~components";
import { apiUrl } from "~config";
import { useOrganization } from "~hooks";
import { showToast } from "~lib";
import { LineItem } from "~components/orders";
import {
  CERT,
  INTERMEC_VENDOR_ID,
  HONEYWELL_VENDOR_ID,
  toUTF8Array,
} from "~config/print";
import { LineItemType, OrderStatus } from "~graphql/sdk";

interface PrintModalProps {
  isOpen: boolean;
  close: () => void;
  isConfigure?: boolean;
  printOnOpen?: boolean;
  order?: any;
  tickets?: any[];
  enableReceipts?: boolean;
}

interface Ticket {
  firstName?: string;
  id: string;
  isNamed: boolean;
  lastName?: string;
  lineItem: TicketLineItem;
  seatLabel: string;
  seatSection: string;
  seatTags: any;
  seatZone: string;
  shouldBeNamed: false;
  status: string;
  ticketNumber: string;
}

interface TicketLineItem {
  seatLabel: string;
  seatSection: string;
}

function printerLanguage(printerName: string): string {
  if (!printerName) {
    return "zpl";
  }

  if (
    printerName.toLowerCase().includes("epson") ||
    printerName.toLowerCase().includes("slk")
  ) {
    return "escpos";
  }

  return "zpl";
}

export const SelectablePrintModal: FC<PrintModalProps> = ({
  isOpen,
  isConfigure,
  close,
  printOnOpen,
  order,
  tickets: activeTickets,
  enableReceipts = false,
}) => {
  const { organization } = useOrganization();
  const [isConnected, setConnected] = useState(false);
  const [isPrintingFromConfig, setPrintingFromConfig] = useState(false);
  const [connectedPrinter, setConnectedPrinterState] = useState(null);
  const [labelShift, setLabelShiftState] = useState(0);
  const [labelTop, setLabelTopState] = useState(0);
  const [printableTicketList, setPrintableTicketList] = useState([]);
  const [showPrintableTickets, setShowPrintableTickets] = useState(false);
  const [markAsPreprint, setMarkAsPreprint] = useState(false);

  const updateLocalConfig = (config?: any) => {
    localStorage.setItem(
      `printer-config-${organization.id}`,
      JSON.stringify({
        connectedPrinter: config?.printer || connectedPrinter,
        labelShift: config?.labelShift || labelShift,
        labelTop: config?.labelTop || labelTop,
      })
    );
  };

  const setConnectedPrinter = (config) => {
    updateLocalConfig({ printer: config });
    setConnectedPrinterState(config);
  };

  const isActive = () => {
    try {
      const res = qz.websocket.isActive();
      return res;
    } catch (e) {
      return false;
    }
  };

  const tickets = useMemo(
    () =>
      activeTickets?.filter(
        ({ lineItem: { type } }) =>
          type === LineItemType.Ticket || type === LineItemType.Membership
      ),
    [activeTickets]
  );

  const addOns = useMemo(
    () =>
      activeTickets?.filter(
        ({ lineItem: { type } }) => type === LineItemType.Addon
      ),
    [activeTickets]
  );

  // Fallback in case no USB match
  const connectUsingFind = async () => {
    let honeywellPrinter;
    let intermecPrinter;
    let epsonPrinter;

    try {
      honeywellPrinter = await qz.printers.find("honeywell");
    } catch (e) {
      console.log("No Honeywell printer found");
    }

    if (!honeywellPrinter) {
      try {
        intermecPrinter = await qz.printers.find("intermec");
      } catch (e) {
        console.log("No Intermec printer found");
      }
    }

    if (!intermecPrinter) {
      try {
        epsonPrinter = await qz.printers.find("epson");
      } catch (e) {}
    }

    if (!intermecPrinter && !honeywellPrinter && !epsonPrinter) {
      showToast("Could not find printer.", "error");
      return;
    }

    let printerConfig;
    try {
      printerConfig = await qz.configs.create(
        honeywellPrinter || intermecPrinter
      );
    } catch (e) {
      console.log(e);
    }

    if (printerConfig) {
      setConnectedPrinter(honeywellPrinter || intermecPrinter || epsonPrinter);
    } else {
      showToast("Could not find printer.", "error");
    }
  };

  const findPluggedInPrinter = async () => {
    let printers: string[];

    try {
      printers = await qz.printers.find();
    } catch (e) {
      return null;
    }

    const filteredPrinters = printers.filter(
      (p) =>
        p.toLowerCase().includes("honeywell") ||
        p.toLowerCase().includes("intermec") ||
        p.toLowerCase().includes("epson") ||
        p.toLowerCase().includes("slk")
    );

    if (filteredPrinters.length === 0) {
      return null;
    }

    try {
      return await new Promise<string>((resolve, reject) => {
        const timer = setTimeout(() => {
          reject(new Error(`Could not find printer.`));
        }, 2000);

        qz.printers.startListening(filteredPrinters).then(() => {
          console.log("Searching for printer");
          qz.printers.getStatus();
          qz.printers.setPrinterCallbacks((event) => {
            if (event.statusText === "OK") {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              console.log(`Found connected printer: ${event.printerName}`);
              clearTimeout(timer);
              resolve(event.printerName as string);
            }
          });
        });
      });
    } catch {
      return null;
    }
  };

  const findPrinter = async () => {
    if (isConnected && isActive()) {
      if (connectedPrinter) {
        return;
      }

      const devices = await qz.usb.listDevices(false);
      if (!devices) {
        await connectUsingFind();
        return;
      }

      const printer = find(devices, (d) =>
        [INTERMEC_VENDOR_ID, HONEYWELL_VENDOR_ID].includes(d?.vendorId)
      );

      if (!printer) {
        connectUsingFind();
        return;
      }

      const { vendorId, productId } = printer;

      try {
        const printerConfig = await new Promise((resolve, reject) => {
          qz.usb
            .listInterfaces({ vendorId, productId })
            .then((interfaces) => {
              if (!interfaces?.length) {
                reject();
                return;
              }

              qz.usb
                .listEndpoints({
                  vendorId,
                  productId,
                  interface: interfaces?.[0],
                })
                .then((endpoints) => {
                  if (!endpoints?.length) {
                    reject();
                    return;
                  }

                  resolve({
                    vendorId,
                    productId,
                    interface: interfaces?.[0],
                    endpoint: endpoints?.[0],
                  });
                })
                .catch(() => {
                  reject();
                });
            })
            .catch(() => {
              reject();
            });
        });

        let connected = false;

        try {
          const isClaimed = await qz.usb.isClaimed(printerConfig);

          if (isClaimed) {
            showToast("Printer has already been claimed.", "error");
          } else {
            await qz.usb.claimDevice(printerConfig);

            connected = true;
            setConnectedPrinter(printerConfig);

            qz.usb.releaseDevice(printerConfig);
          }
        } catch (e) {}

        if (!connected) {
          await connectUsingFind();
        }
      } catch (e) {
        showToast("Could not connect to printer.", "error");
      }

      qz.usb
        .listDevices(false)
        .then(async (devices) => {})
        .catch(() => {
          connectUsingFind();
        });
    }
  };

  const initialize = () => {
    qz.security.setCertificatePromise((resolve) => {
      resolve(CERT);
    });

    qz.security.setSignaturePromise((toSign) => async (resolve) => {
      try {
        const res = await axios.post(
          `${apiUrl}/ticket/printing/sign`,
          {
            message: toSign,
          },
          {
            params: {
              "flicket-org-id": organization?.id,
            },
          }
        );

        resolve(res.data.signature);
      } catch (e) {
        console.log("Error with qz.security.setSignaturePromise", e);
        resolve();
      }
    });
  };

  const connectWithoutPrinting = () => {
    if (!isActive()) {
      qz.websocket.connect({ retries: 1, delay: 0.3 }).then(() => {
        setConnected(true);
      });
    } else {
      setConnected(true);
    }
  };

  const tryConnecting = async () => {
    const localConfig = localStorage.getItem(
      `printer-config-${organization.id}`
    );

    if (localConfig) {
      try {
        const parsedLocalConfig = JSON.parse(localConfig);

        setLabelShiftState(parseInt(parsedLocalConfig.labelShift));
        setLabelTopState(parseInt(parsedLocalConfig.labelTop));

        if (
          isConfigure ||
          !printOnOpen ||
          !parsedLocalConfig?.connectedPrinter
        ) {
          connectWithoutPrinting();
          return;
        }

        if (isPrintingFromConfig) {
          return;
        }

        setPrintingFromConfig(true);

        if (!isActive()) {
          await qz.websocket.connect();
        }
        setConnected(true);

        if (printableTicketList.length > 0) {
          const result = await printCurrentOrder(parsedLocalConfig);

          if (result) {
            showToast("Print job started.");
            close();
          } else {
            showToast(
              "Could not print using current configuration, please check all the steps and try again.",
              "error"
            );
          }
        }

        setPrintingFromConfig(false);

        return;
      } catch (e) {
        console.log(e);
        setPrintingFromConfig(false);

        showToast(
          "Could not print using current configuration, please check all the steps and try again.",
          "error"
        );
      }
    }

    connectWithoutPrinting();
  };

  useEffect(() => {
    if (isOpen) {
      initialize();

      tryConnecting();

      setPrintableTicketList(tickets);
    }

    try {
      qz.websocket.setClosedCallbacks(() => {
        setConnected(false);
        setConnectedPrinter(null);
      });
    } catch (e) {}

    return () => {
      try {
        qz.websocket.setClosedCallbacks([]);
      } catch (e) {}
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isPrintingFromConfig && isOpen) {
      findPrinter();
    }
  }, [isConnected]);

  const printCurrentOrder = async (config) =>
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    new Promise(async (resolve) => {
      if (!isActive() || !config.connectedPrinter) {
        showToast("Could not connect to printer.", "error");
        resolve(false);
        return;
      }

      if (!printableTicketList.length) {
        resolve(false);
        return;
      }

      if (!isConnected) {
        connectWithoutPrinting();

        // Wait for qz tray to connect
        await new Promise((resolve) => setTimeout(resolve, 1500));
      }

      let pluggedInPrinter = "";
      if (qz.api.isVersionGreater(2, 2, 0)) {
        pluggedInPrinter = await findPluggedInPrinter();
      }

      try {
        const res = await axios.get(`${apiUrl}/ticket/hardcopy`, {
          params: {
            "flicket-org-id": organization?.id,
            ticketIds: printableTicketList?.map((ticket) => ticket.id).join(),
            orderId: order.id,
            format: printerLanguage(pluggedInPrinter),
            preprint: markAsPreprint ? "true" : null,
            membership: order.membership ? true : null,
            ...(enableReceipts && { receipt: 1 }),
          },
        });

        const { data: ticketsData } = res;

        let positionedTickets = ticketsData.replace(
          /\^XA/gm,
          `^XA^LS${
            !Number.isNaN(config.labelShift) ? config.labelShift : 0
          }^LT${!Number.isNaN(config.labelTop) ? config.labelTop : 0}`
        );

        if (config.connectedPrinter?.vendorId) {
          try {
            await qz.usb.claimDevice(config.connectedPrinter);
            let success = false;

            try {
              await qz.usb.sendData({
                ...config.connectedPrinter,
                data: toUTF8Array(positionedTickets),
              });
              success = true;
            } catch (e) {
              showToast(`Error printing tickets: ${e}`, "error");
            }

            try {
              await qz.usb.releaseDevice(config.connectedPrinter);
            } catch (e) {}

            if (success) {
              resolve(true);
            } else {
              resolve(false);
            }
            return;
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            if (pluggedInPrinter) {
              const currentConfig = await qz.configs.create(pluggedInPrinter);

              // Adjust alignment on Honeywell printers
              if (pluggedInPrinter.toLowerCase().includes("honeywell")) {
                positionedTickets = positionedTickets.replace(
                  "^XA",
                  `
                  ^XA
                  ^LH0,0
                  ^MNN
                  ^LL1120
                  ^PW575
                `
                );

                // Replace if no labelTop set in config
                positionedTickets = positionedTickets.replace(
                  "^LT0",
                  "^LT-100"
                );
              } else if (printerLanguage(pluggedInPrinter) === "escpos") {
                positionedTickets = {
                  type: "raw",
                  format: "command",
                  flavor: "base64",
                  data: positionedTickets,
                };
              }

              await qz.print(currentConfig, [positionedTickets]);
              resolve(true);
              return;
            }

            const currentConfig = await qz.configs.create(
              config.connectedPrinter
            );
            await qz.print(currentConfig, [positionedTickets]);
            resolve(true);
            return;
          } catch (e) {
            showToast(`Error printing: ${e}`, "error");
          }
        }
      } catch (e) {
        console.log(e);
      }

      resolve(false);
      return;
    });

  const onPrint = async () => {
    tryConnecting();
  };

  const togglePrintAllList = (addAll: boolean) => {
    setPrintableTicketList(addAll ? tickets : []);
  };

  const togglePrintableTicket = (currentTicket: Ticket, addTicket: boolean) => {
    let newTickets: Ticket[] = [];

    newTickets = tickets?.filter(
      (ticket: Ticket) =>
        (addTicket && ticket.id === currentTicket.id) ||
        (ticket.id !== currentTicket.id &&
          printableTicketList.some((item) => item.id === ticket.id))
    );

    setPrintableTicketList(newTickets);
  };

  return (
    <ModalBase isOpen={isOpen} close={close}>
      <Text variant="extraBold.L" color="N800">
        {"Print tickets"}
      </Text>
      <Divider mt={2} mb={3} />
      {tickets?.length && (
        <Flex flex={1} justifyContent="space-between">
          <Checkbox
            label="Print all tickets"
            checked={printableTicketList?.length === tickets?.length}
            onChange={(e: any) => togglePrintAllList(e.target.checked)}
          />
          <TransparentButton
            onClick={() => setShowPrintableTickets(!showPrintableTickets)}
            flex={1}
            justifyContent="flex-end"
          >
            {printableTicketList?.length > 0 && (
              <Text fontSize={2} fontWeight="heavy">
                ({printableTicketList.length} ticket
                {printableTicketList.length > 1 && "s"} selected)
              </Text>
            )}
            <Icon
              icon="chevron-down"
              ml="1/4"
              css={`
                transform: rotate(${showPrintableTickets ? 180 : 0}deg);
                transition: transform ease-in-out 0.3s;
              `}
            />
          </TransparentButton>
        </Flex>
      )}

      {order?.status === OrderStatus.Hold && (
        <>
          <Divider mt={2} mb={3} />

          <Checkbox
            label="Mark order as preprint"
            checked={markAsPreprint}
            onChange={(e: any) => setMarkAsPreprint(e.target.checked)}
          />
          <Text color="N800" mb={2}>
            {
              "Please note that preprinted tickets can be scanned in but won't count towards the total number of tickets sold or revenue until the order is completed."
            }
          </Text>
        </>
      )}

      <Divider mt={2} mb={3} />

      {isPrintingFromConfig ? (
        <Spinner color="P300" />
      ) : (
        <>
          {showPrintableTickets && (
            <Box mt={4}>
              {tickets?.length > 0 && (
                <Box as="ul">
                  {tickets?.map((ticket) => (
                    <Flex
                      flex={1}
                      justifyContent="space-between"
                      key={ticket.id}
                    >
                      <Checkbox
                        label={`${ticket?.lineItem.name} | (#${ticket?.ticketNumber})`}
                        onChange={(e: any) =>
                          togglePrintableTicket(ticket, e.target.checked)
                        }
                        cursor="pointer"
                        checked={Boolean(
                          printableTicketList.length > 0 &&
                            printableTicketList?.find(
                              (printTicket: Ticket) =>
                                printTicket.id === ticket.id
                            )
                        )}
                      />
                    </Flex>
                  ))}

                  <Divider mt={2} mb={3} />
                </Box>
              )}

              {addOns?.length > 0 && (
                <Box as="ul">
                  {addOns?.map((item) => (
                    <Fragment key={item.id}>
                      <LineItem
                        {...item}
                        fullName={order?.user?.fullName}
                        key={item?.id}
                      />
                    </Fragment>
                  ))}
                </Box>
              )}
            </Box>
          )}

          <Flex justifyContent="flex-end" alignItems="center" mt={4}>
            <PrimaryButton fontSize={2} onClick={onPrint}>
              {"Print tickets"}
            </PrimaryButton>
          </Flex>
        </>
      )}
    </ModalBase>
  );
};
