/**
 * This guard will inform the TypeScript compiler that no argument should ever
 * be passed to this guard function, and that the function should never return a value.
 *
 * @param _value - The value we're guarding against.
 * @returns Never, because this function should never be called.
 * @throws Error when called.
 */
export function exhaustiveGuard(_value: never): never {
  throw new Error(
    `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(
      _value
    )}`
  );
}
