import React, { forwardRef, useContext } from "react";
import { system, SystemProps } from "flicket-ui";
import ReactSelect, { Props, components } from "react-select";
import styled, { css, DefaultTheme, ThemeContext } from "styled-components";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {/*<Icon className="chevron" icon="arrow-dropdown" height="24px" width="24px" mr={2} />*/}
    </components.DropdownIndicator>
  );
};

const disabledStyles = ({ styledTheme: theme }) => css`
  background: ${theme.colors.N100};
  color: ${theme.colors.N500};
  .react-select__placeholder {
    color: ${theme.colors.N500};
  }
  .react-select__dropdown-indicator {
    svg {
      color: ${theme.colors.N500};
    }
  }
`;

const StyledSelect = styled(ReactSelect)<SystemProps & { isInvalid?: boolean }>(
  ({ styledTheme: theme, isInvalid }) => css`
    font-size: 16px;
    font-weight: ${theme.fontWeights.medium};
    ${system}

    .react-select {
      &__placeholder {
        color: ${theme.colors.N500};
        margin: 0;
        font-weight: ${theme.fontWeights.medium};
      }

      &__value-container {
        height: 100%;
      }

      &__control {
        cursor: pointer;

        min-height: 50px;
        transition: border-color 0.18s ease;
        border-radius: ${theme.radii.sm};
        box-shadow: ${theme.shadows.sm};
        border-color: ${theme.colors[isInvalid ? "error" : "N200"]};

        &:hover {
          border-color: ${theme.colors.N300};
        }

        .react-select__dropdown-indicator {
          .chevron {
            transition: all 0.25s cubic-bezier(0.09, 1.08, 0.77, 1.18);
          }

          svg {
            color: ${theme.colors.P300};
          }
        }

        &--menu-is-open {
          .react-select__dropdown-indicator {
            .chevron {
              transform: rotate(180deg);
            }
          }
        }

        &--is-disabled {
          ${disabledStyles({ styledTheme: theme })}
        }
      }

      &__option {
        cursor: pointer;

        padding: 8px;
        margin: 0 8px 4px;
        width: calc(100% - 16px);
        line-height: 1;
        border-radius: ${theme.radii.xs};
        color: ${theme.colors.N700};
        font-size: 16px;
        font-weight: ${theme.fontWeights.medium};

        &--is-disabled {
          ${disabledStyles({ styledTheme: theme })}
        }
      }

      &__menu {
        border: none;
        border-radius: ${theme.radii.sm};
        box-shadow: ${theme.shadows.hover};
        margin-top: 4px;
        z-index: 9999;

        &-list {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }

      &__value-container {
        padding: 8px 8px 8px 16px;
      }

      @media (max-width: ${theme.breakpoints.sm}) {
        &__value-container {
          padding: 8px 8px 8px 8px;
        }
      }

      &__indicator-separator {
        display: none;
      }
    }
  `
);

interface SelectProps extends Props {
  theme?: any;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const styledTheme = useContext(ThemeContext); // react-select and styled-components both need a theme so it needs to be renamed

  return (
    <StyledSelect
      inputRef={ref}
      components={{ DropdownIndicator }}
      className="react-select"
      classNamePrefix="react-select"
      styledTheme={styledTheme}
      theme={(t: DefaultTheme) => ({
        ...t,
        colors: {
          ...t.colors,
          primary25: styledTheme.colors.P100,
          primary50: styledTheme.colors.P100,
          primary: styledTheme.colors.P100,
        },
      })}
      {...props}
    />
  );
});

Select.displayName = "Select";

export { Select };
export default Select;
