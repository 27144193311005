import React, { FC } from "react";
import { Flex, PrimaryButton, system } from "flicket-ui";
import styled from "styled-components";

export const Grid = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 40px;

  ${system}
`;

export const SubmitButton: FC<{ form: string }> = ({ children, form }) => (
  <PrimaryButton type="submit" form={form} lineHeight="normal">
    {children}
  </PrimaryButton>
);
