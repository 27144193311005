import React from "react";

import { TertiaryButton } from "flicket-ui";
import { Icon } from "~components";

export const AddButton = ({ children, ...props }) => {
  return (
    <TertiaryButton type="button" width="auto" px={2} fontSize={2} {...props}>
      <Icon icon="add" fontSize={6} mr={1} />
      {children}
    </TertiaryButton>
  );
};
