export const CERT = `-----BEGIN CERTIFICATE-----
MIIFHDCCAwSgAwIBAgIQNzkyMDIzMDYwMzA0MTQxMjANBgkqhkiG9w0BAQsFADCB
mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry
aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ
cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz
dHJpZXMuY29tMB4XDTIzMDYwMzA0MTQxMloXDTI1MDUxOTAyMjIzNFowgdcxCzAJ
BgNVBAYMAk5aMREwDwYDVQQIDAhBdWNrbGFuZDERMA8GA1UEBwwIQXVja2xhbmQx
FDASBgNVBAoMC0ZsaWNrZXQgTHRkMRQwEgYDVQQLDAtGbGlja2V0IEx0ZDEUMBIG
A1UEAwwLRmxpY2tldCBMdGQxIjAgBgkqhkiG9w0BCQEME2ppbW15QGZsaWNrZXQu
Y28ubnoxPDA6BgNVBA0MM3JlbmV3YWwtb2YtZjU5MjNlNTZhNjFiY2UwOGQxZjgz
Mjc1NDAyMjMyZWM5OWVhZDQ4MzCCASAwCwYJKoZIhvcNAQEBA4IBDwAwggEKAoIB
AQCxxIZt47Foe2BABvF4GLRDM5BGURFF+NHIlMkDMGvHSargzFN3W8GsY7Oz2Amc
ZqGhkxySPQZy2T4QJIvkZvx5BE4V65yao9ZJJzRjqTyXxe1fdfkNU72SshxGgiVv
eyMGzdoi8sJ9ETP8plmcMM7jwq33kSHPwDCD6QDHIt+9mvVAxmyCr5EjOTyRbtHc
dwgGIYROOpB6XKsVImek69nabXglhCfjzTdBoQus+5RL9PXjs+VFGT/J6IY4byVL
i+Z9sUy91EAicK7i6yr3yg7AGgrIivza0E8US4Wi0D5MKhwP18jVtWLINV7XSzmF
7C16hEglxaQn1epKviPmufOfAgMBAAGjIzAhMB8GA1UdIwQYMBaAFJCmULeE1Lnq
X/IFhBN4ReipdVRcMA0GCSqGSIb3DQEBCwUAA4ICAQApOn2YaKow4xfgkU810t6y
SZAVJpddUM8N2TIpxh5mcn1pRGpGjVbexZ7Uhqf1afybuNyp8TvRksvHYeN7iUSC
REXyQDcx3M+OaoqOtawOHWyGhy090+8l4ka8LoZFPkpUbtz07r72PtxH0T8p7cBx
hQ4JndinR1tFa+DlTZ0SUNdm4vIhZrBxA+JEhHQxN//QjKT8/T/m/sQ0yViTD02f
iutSdV6D5+WHFctFCCNMA9uVj+76IQoYcyDyFXo7AoJZN/Tf6jfzsSLNUaSGkVzb
u+sGI4z+SKEJDs3EQIkuTHhNmaGSw548CZYqEK7kbVJy0M48HFdkjTY+ts/pdw8O
aXEW6nq73frfSJLC9U5eVctrvmxizpRxYJJthFJRBIUFdi/wOnVAAsxTBkA1BC+J
VA81xaa3yaGlacsKVYrAK4LmYXBGzHUK7V9QACrWokhXfMiFKzNH0ELTwjppHAOz
/lp9tvHW5+/pI7Ha8lLEjK8OYj7WLSLczF9DCWbtvuKCG7AYj6KjCJKITb3XM0hq
al5vAXwr/Y9g4y1LP9zonHHgk5erzLxnRpWsizoh00m80xJgWXedoS94bW+rN+4Y
XA27BwXqnCyIn7xGPbP+02x6qjXRsNj4EsJU8nWGwjbrN8g6o+Ri6B/fUb7GUk35
C+95ktZhCz5svSVeZrcnOg==
-----END CERTIFICATE-----
--START INTERMEDIATE CERT--
-----BEGIN CERTIFICATE-----
MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT
MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ
bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG
A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx
emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow
gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0
cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM
EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1
c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU
iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H
YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM
GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH
EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh
0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX
xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1
9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE
ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD
2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX
6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY
5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ
plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S
BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ
eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k
gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT
x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu
pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw
2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp
rbO7BnjW
-----END CERTIFICATE-----`;

export const INTERMEC_VENDOR_ID = "067e";
export const HONEYWELL_VENDOR_ID = "0c2e";

export const toUTF8Array = (str) => {
  const utf8 = [];
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
    // surrogate pair
    else {
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode =
        0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
  }
  return utf8;
};
