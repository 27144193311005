import { sdk } from "~lib";
import { isSuperAdminDomain } from "~lib/helpers";
import { useOrganization } from "./useOrganization";

export const useSDK = () => {
  const { organization } = useOrganization();

  if (!organization && !isSuperAdminDomain()) {
    console.warn(
      "There is no or invalid organization set, check the subdomain"
    );
  }

  return sdk({ orgId: organization?.id });
};
