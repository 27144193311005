import { Permission } from '~graphql/sdk';
import Error from '~pages/_error';
import useUser from './useUser';

export const usePermissions = () => {
  const { user } = useUser();

  const hasPermissions = (requiredPermissions: Permission | Permission[]) => {
    if (Array.isArray(requiredPermissions)) {
      return requiredPermissions?.every((permission) =>
        user?.permissions.includes(permission),
      );
    }

    return user?.permissions.includes(requiredPermissions);
  };

  return { hasPermissions, Permission, Error };
};
