import { Flex, system } from 'flicket-ui';
import styled from 'styled-components';

export const Card = styled(Flex).attrs({
  flexDir: 'column',
  position: 'relative',
  width: 1,
  bg: 'white',
  borderRadius: 'md',
  boxShadow: 'sm',
})`
  flex: 1;

  ${system}
`;
