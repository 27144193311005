import React from "react";
import { Text, Flex, SecondaryButton } from "flicket-ui";
import { NamingFieldsOverviewProps } from ".";
import { useNamingFieldOptions } from "./hooks/useNamingFieldOptions";
import { NamingFieldsTag } from "./NamingFieldsTag";
import { FieldLabel } from "~features/generalAdmissionEvent/components/FormSectionHelpers";

export const NamingFieldsOverview = ({
  fields,
  onClick,
  hideTitle = false,
}: NamingFieldsOverviewProps) => {
  const { namingFieldOptions } = useNamingFieldOptions(fields);

  return (
    <>
      {!hideTitle && (
        <Text color="N600" fontSize={3} fontWeight="extraBold" mb="1/2">
          Naming fields
        </Text>
      )}
      <Flex width={1} alignItems="center">
        <Flex alignItems="flex-start" flexWrap="wrap">
          {fields
            ?.filter((f) => f.isRequired)
            .map(({ field }) => (
              <FieldLabel key={field} mr={1}>
                {
                  Object.entries(namingFieldOptions).find(
                    ([, { field: f }]) => f === field
                  )?.[0]
                }
                *
              </FieldLabel>
            ))}

          {fields
            ?.filter((f) => !f.isRequired)
            .map(({ field }) => (
              <FieldLabel key={field} mr={1}>
                {
                  Object.entries(namingFieldOptions).find(
                    ([, { field: f }]) => f === field
                  )?.[0]
                }
              </FieldLabel>
            ))}
        </Flex>
        <Text
          color="P300"
          fontSize={3}
          fontWeight="medium"
          textDecoration="underline"
          cursor="pointer"
          onClick={onClick}
        >
          Edit
        </Text>
      </Flex>
    </>
  );
};
