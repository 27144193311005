import { uploadsFolder } from "~config";
import { ExtendedFile } from "~graphql/sdk";

export const getImage = (
  file: ExtendedFile,
  fallback: string = undefined
): string =>
  file
    ? `${uploadsFolder}/${file?.directory}/${file?.fileName}`
    : fallback ?? "/static/no-image.jpg";
