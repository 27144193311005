import React, { ReactNode, ReactElement, CSSProperties } from "react";
import { Text, Flex, Box, Spinner, SystemProps } from "flicket-ui";
import Link from "next/link";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import styled from "styled-components";

import { Divider, Icon, SearchInput } from "~components";
import { getError } from "~lib/helpers";
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true,
});

const StyledTableBodyWrapper = styled(Box)`
  flex: none;
  min-height: 400px;

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    flex: 1;
    height: auto;
    min-height: auto;
  }
`;

export const GridTable = styled(Flex)<{ col: number | string }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(p) =>
    typeof p.col === "number" ? `repeat(${p.col}, 1fr)` : p.col};
`;

interface RowItemProps {
  first?: boolean;
  last?: boolean;
  highlightLastRow?: boolean;
  isLastRow?: boolean;
}

export const RowItem = styled(Flex).attrs<RowItemProps>({
  alignItems: "center",
  p: 2,
})<RowItemProps>`
  ${(p) =>
    p.first &&
    `
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  `}
  ${(p) =>
    p.last &&
    `
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  `};

  ${(p) => p.isLastRow && p.highlightLastRow && `background-color: #ccc;`};
`;

type TableWrapperProps = {
  total: number;
  loading: boolean;
  error: Error;
  title: string;
  children: ReactNode;
  tableWrapperStyle?: CSSProperties;
};

export const TableWrapper = ({
  total,
  loading,
  error,
  title,
  children,
  tableWrapperStyle = {},
}: TableWrapperProps) => (
  <Flex
    width={1}
    flex={1}
    flexDir="column"
    position="relative"
    bg="white"
    style={tableWrapperStyle}
    borderRadius="md"
    boxShadow="sm"
  >
    {!loading && !error && title && (
      <Text
        color="N400"
        variant="extraBold.L"
        position="absolute"
        top="-56px"
        left={0}
      >
        {total} {title}
      </Text>
    )}
    {children}
  </Flex>
);

export type TrProps<T extends RowData> = {
  row: T;
  headers: ((
    | {
        key: keyof T;
        render?: undefined;
      }
    | {
        key?: undefined;
        render: (row: T) => ReactNode;
      }
  ) &
    DefaultHeaderProps<T>)[];
  route?: string;
  style?: CSSProperties;
  index: number;
  highlightLastRow?: boolean;
  isLastRow?: boolean;
  routeFunction?: (row: T) => string;
};

export const Tr: <T extends RowData>(props: TrProps<T>) => ReactElement = ({
  headers,
  row,
  route,
  style = {},
  index,
  highlightLastRow,
  isLastRow,
  routeFunction,
}) => (
  <Flex style={style} key={row.id}>
    {headers.map(({ flex, ...item }, i) => {
      const rowItem = (
        <RowItem
          bg={index % 2 ? ("#FAFAFC" as any) : "transparent"}
          first={i === 0}
          last={i === headers.length - 1}
          flex={flex}
          as={item.excludeLink ? "div" : "a"}
          justifyContent={
            typeof item.align === "string"
              ? item.align
              : typeof item.align === "function"
              ? item.align(row)
              : "initial"
          }
          highlightLastRow={highlightLastRow}
          isLastRow={isLastRow}
          minW={item.minW}
        >
          <Text color="N700" fontSize={3} lineHeight="high" d="flex">
            {item.render ? item.render(row) || "-" : row[item.key] || "-"}
          </Text>
        </RowItem>
      );

      return (
        <React.Fragment key={i}>
          {item.excludeLink ? (
            <>{rowItem}</>
          ) : routeFunction ? (
            <Link href={`${routeFunction(row)}`}>{rowItem}</Link>
          ) : route ? (
            <Link href={`/${route}/[id]`} as={`/${route}/${row.id}`}>
              {rowItem}
            </Link>
          ) : (
            <>{rowItem}</>
          )}
        </React.Fragment>
      );
    })}
  </Flex>
);

export const Search = ({ onChange }: { onChange: (value: string) => void }) => (
  <Box p={4} pt={3} width={1}>
    <SearchInput
      onChange={onChange}
      width="100%"
      mb={3}
      searchIcon={<Icon icon="search" />}
    />
  </Box>
);

interface RowData {
  id: string;
}

interface DefaultHeaderProps<T extends RowData> extends SystemProps {
  label: string | (() => JSX.Element);
  flex?: string | number;
  excludeLink?: boolean;
  align?: string | ((row: T) => string);
}

export interface ITableBody<T extends RowData> {
  rows: T[];
  route?: string;
  headers: ((
    | {
        key: keyof T;
        render?: undefined;
      }
    | {
        key?: undefined;
        render: (row: T) => ReactNode;
      }
  ) &
    DefaultHeaderProps<T>)[];
  error?: Error;
  loading?: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => Promise<any>;
  total?: number;
  customHeader?: () => JSX.Element;
  filters?: () => JSX.Element;
  // Make the text on last row bolder
  highlightLastRow?: boolean;
  routeFunction?: (row: T) => string;
}

export const TABLE_LIST_ITEM_HEIGHT = 56;

export const TableBody: <T extends RowData>(
  props: ITableBody<T> & { height?: number }
) => ReactElement = ({
  headers,
  rows,
  route,
  error,
  loading,
  hasNextPage,
  onLoadMore,
  height,
  total,
  highlightLastRow,
  routeFunction,
}) => {
  const isItemLoaded = (index) => !hasNextPage || index < rows.length;

  const getContent = () => {
    switch (true) {
      case loading || (!rows && !error):
        return (
          <Flex pt={3} width={1} alignItems="center" justifyContent="center">
            <Spinner size={30} color="P300" />
          </Flex>
        );

      case !!error:
        return (
          <Flex pt={3} width={1} alignItems="center" justifyContent="center">
            {getError(error, "graphQL")}
          </Flex>
        );

      case !!rows?.length: {
        const renderRow = ({ index, style }) => {
          const row = rows?.[index];

          if (!isItemLoaded(index)) {
            return (
              <Box style={style} key={index} pt="6/4">
                <Spinner size={30} color="P300" />
              </Box>
            );
          }

          return (
            <Tr
              headers={headers}
              row={row}
              route={route}
              style={
                index === rows.length - 1 && highlightLastRow
                  ? {
                      ...style,
                      fontWeight: 800,
                    }
                  : style
              }
              index={index}
              routeFunction={routeFunction}
              highlightLastRow={highlightLastRow}
              isLastRow={index === rows.length - 1}
            />
          );
        };

        if (onLoadMore) {
          return (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={total || rows?.length}
              loadMoreItems={async () =>
                // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-misused-promises
                new Promise<void>(async (resolve) => {
                  await onLoadMore();

                  resolve();
                })
              }
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={height}
                  width="100%"
                  itemCount={total || rows?.length}
                  itemSize={TABLE_LIST_ITEM_HEIGHT}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {renderRow}
                </List>
              )}
            </InfiniteLoader>
          );
        }

        return (
          <List
            height={height}
            width="100%"
            itemCount={total || rows?.length}
            itemSize={TABLE_LIST_ITEM_HEIGHT}
            onItemsRendered={() => {
              rebuildTooltip();
            }}
          >
            {renderRow}
          </List>
        );
      }

      default:
        return (
          <Flex pt={3} width={1} alignItems="center" justifyContent="center">
            No results
          </Flex>
        );
    }
  };

  return getContent();
};

type TableProps<T extends RowData> = ITableBody<T> & {
  title?: string;
  onSearch?: (val: string) => void;
  children?: ReactNode;
  initialValue?: string;
  tableWrapperStyle?: CSSProperties;
  innerStyle?: CSSProperties;
  disableHeightFromAutosizer?: boolean;
  customHeight?: number;
} & (
    | {
        disableHeightFromAutosizer: true;
        customHeight: number;
      }
    | {
        disableHeightFromAutosizer?: false;
      }
  );

export const Table: <T extends RowData>(
  props: TableProps<T>
) => ReactElement = ({
  headers,
  customHeader,
  rows,
  error,
  loading,
  hasNextPage,
  onLoadMore,
  onSearch,
  children,
  title,
  route,
  total,
  initialValue,
  filters,
  tableWrapperStyle,
  innerStyle = {},
  highlightLastRow,
  disableHeightFromAutosizer = false,
  customHeight,
  routeFunction,
}) => {
  const itemCount = rows?.length + (hasNextPage ? 1 : 0);

  return (
    <>
      <TableWrapper
        loading={loading || (!rows && !error)}
        error={error}
        total={total || rows?.length}
        title={title}
        tableWrapperStyle={tableWrapperStyle}
      >
        {children}

        <Flex
          flexDirection="column"
          px={[2, 2, 4]}
          pt={3}
          flex={1}
          width={1}
          overflow="hidden"
          style={innerStyle}
        >
          {onSearch && (
            <SearchInput
              onChange={(val) => onSearch(val)}
              width="100%"
              mb={2}
              initialValue={initialValue}
              searchIcon={<Icon icon="search" color="N800" />}
            />
          )}

          {filters?.()}

          {headers && typeof customHeader !== "function" && (
            <Flex width={1} pr={onLoadMore ? 2 : 0} overflow={"auto"}>
              {headers.map(({ label, key, flex, ...styles }, i) => (
                <RowItem
                  key={i}
                  borderBottom="1px"
                  borderColor={"#E8E7EB" as any}
                  width={1}
                  flex={flex}
                  {...styles}
                >
                  {typeof label === "function" ? (
                    label()
                  ) : (
                    <Text variant="extraBold.M">{label}</Text>
                  )}
                </RowItem>
              ))}
            </Flex>
          )}
          {typeof customHeader === "function" && customHeader()}
          <StyledTableBodyWrapper pr={onLoadMore ? 2 : 0}>
            <AutoSizer disableWidth disableHeight={disableHeightFromAutosizer}>
              {({ height }) => (
                <TableBody
                  height={disableHeightFromAutosizer ? customHeight : height}
                  headers={headers}
                  rows={rows}
                  total={itemCount}
                  route={route}
                  error={error}
                  loading={loading}
                  hasNextPage={hasNextPage}
                  onLoadMore={onLoadMore}
                  routeFunction={routeFunction}
                  highlightLastRow={highlightLastRow}
                />
              )}
            </AutoSizer>
          </StyledTableBodyWrapper>
        </Flex>
        <Divider mb={0 as any} mt={2} />
      </TableWrapper>
    </>
  );
};
