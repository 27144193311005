import { InputHTMLAttributes, useRef, useState } from "react";
import { Box, Flex, Input, InputWrapper } from "flicket-ui";
import { SketchPicker } from "react-color";
import { useOnClickOutside } from "~hooks";

interface ColorInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "color" | "onChange"> {
  error?: string;
  onChange: (color: string) => void;
}

export const ColorInput = ({
  value,
  error,
  onChange,
  ...props
}: ColorInputProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));
  return (
    <InputWrapper error={error}>
      <Flex>
        <Box mr={2} position="relative">
          <Box
            cursor="pointer"
            borderRadius="xs"
            height={48}
            width={48}
            onClick={() => setOpen(!open)}
            backgroundColor={value as any}
          />
          {open && (
            <Box ref={ref} position="absolute" bottom={50}>
              <SketchPicker
                color={value as string}
                onChangeComplete={(e) => onChange(e.hex)}
              />
            </Box>
          )}
        </Box>
        <Input
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
          {...props}
        />
      </Flex>
    </InputWrapper>
  );
};
