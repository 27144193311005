import {
  Box,
  Flex,
  Input,
  PrimaryButton,
  Radio,
  RadioGroup,
  SecondaryButton,
  system,
  SystemProps,
  Text,
} from "flicket-ui";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { ColorInput, Divider, Select } from "~components";
import { useOrganization, useSDK } from "~hooks";
import { showErrorToast } from "~lib";
import useSWR from "swr";
import { useReleases } from "~graphql";

const StyledLabel = styled.label<SystemProps>`
  padding-bottom: 8px;

  color: ${(p) => p.theme.colors.N600};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.extraBold};
  letter-spacing: -0.165px;

  ${system}
`;

const suggestedLinkTypes = [
  "access-your-tickets",
  "event-information",
  "event-registration",
  "event-tickets",
  "membership-renewal",
  "event-ticket-resale",
] as const;

export type SuggestedLinkType = typeof suggestedLinkTypes[number];

type ModalType = "link" | "button";
type ModalOption = {
  type: ModalType;
  label: string;
};

const modalOptions: ModalOption[] = [
  {
    type: "link",
    label: "Text link",
  },
  {
    type: "button",
    label: "Button",
  },
];

export type InsertButtonOrLinkOnSuccessOptions = {
  type: ModalType;
  url?: string;
  content?: string;
  color?: string;
  suggestedLink?: SuggestedLinkType;
  eventId?: string;
  releaseId?: string;
};

type InsertModalContentProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isEmail: boolean;
  isEdit?: boolean;
  defaultValues?: {
    url?: string;
    content?: string;
    color?: string;
    suggestedLink?: SuggestedLinkType;
    type?: ModalType;
    selectEvents?: boolean;
    eventId?: string;
    releaseId?: string;
  };
  onSuccess: ({
    url,
    content,
    color,
    suggestedLink,
    type,
    eventId,
    releaseId,
  }: InsertButtonOrLinkOnSuccessOptions) => void;
};

const headerTextMap: Record<SuggestedLinkType, string> = {
  "access-your-tickets": "Link to their tickets",
  "event-information": "Link to event page",
  "event-registration": "Link to event registration",
  "event-tickets": "Link to tickets page",
  "event-ticket-resale": "Link to tickets page",
  "membership-renewal": "Link to membership renewal",
};

const getHeaderText = ({
  suggestedLink,
  type,
  isEdit,
}: {
  suggestedLink?: SuggestedLinkType | undefined;
  type: ModalType;
  isEdit: boolean;
}) => {
  const text = suggestedLink ? headerTextMap[suggestedLink] : undefined;
  if (!suggestedLink) {
    let prefix = "Insert",
      suffix = "link";
    if (isEdit) {
      prefix = "Edit";
    }
    if (type === "button") {
      suffix = "button";
    }
    return `${prefix} ${suffix}`;
  }

  return isEdit ? `Edit ${text.toLowerCase()}` : text;
};

export const InsertModalContent = ({
  setIsOpen,
  isEmail,
  isEdit,
  defaultValues,
  onSuccess,
}: InsertModalContentProps) => {
  const { organization } = useOrganization();
  const [url, setUrl] = useState(defaultValues?.url ?? "");
  const [content, setContent] = useState(defaultValues?.content ?? "");
  const [color, setColor] = useState(
    defaultValues?.color ?? organization.branding.primaryColor
  );
  const suggestedLink = defaultValues?.suggestedLink;
  const [type, setType] = useState<ModalType>(defaultValues?.type ?? "link");
  const [eventId, setEventId] = useState<string | undefined>(
    defaultValues?.eventId
  );
  const [releaseId, setReleaseId] = useState<string | undefined>(
    defaultValues?.releaseId
  );
  const sdk = useSDK();

  const headerText = getHeaderText({
    type,
    isEdit,
    suggestedLink,
  });

  const shouldLoadEvents =
    suggestedLink && (defaultValues?.selectEvents || defaultValues?.eventId);

  const { data: paramsEvents } = shouldLoadEvents
    ? useSWR("paramsEvents", async () =>
        sdk
          .paramsEvents()
          .then((res) => res?.events?.edges?.map(({ node }) => node))
      )
    : { data: null };

  const { data: releaseData } = useReleases(
    suggestedLink == "event-tickets" ? eventId : null
  );

  return (
    <>
      <Box d="flex" alignItems="center">
        <Text fontWeight="heavy" fontSize={6}>
          {headerText}
        </Text>
      </Box>
      <Divider />
      {paramsEvents?.length ? (
        <Select
          options={paramsEvents?.map((event) => ({
            label: event.title,
            value: event.id,
          }))}
          defaultValue={defaultValues?.eventId}
          label={"Event"}
          onChange={setEventId}
          mt={4}
        />
      ) : null}
      {releaseData?.length ? (
        <Select
          options={releaseData?.map((release) => ({
            label: release.name,
            value: release.id,
          }))}
          defaultValue={defaultValues?.releaseId}
          label={"Release"}
          onChange={setReleaseId}
          mt={4}
        />
      ) : null}
      {isEmail && !isEdit && (
        <Box mt={4}>
          <StyledLabel>Display as</StyledLabel>
          <RadioGroup
            toggle
            value={type}
            onChange={(type) => setType(type as ModalType)}
            space={0}
            css={`
              margin-top: 8px;
            `}
          >
            {modalOptions.map(({ label, type }) => (
              <Radio key={type} value={type}>
                <Text>{label}</Text>
              </Radio>
            ))}
          </RadioGroup>
        </Box>
      )}

      <Input
        label="Display text"
        value={content}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setContent(e.target.value)
        }
        mt={4}
      />

      {!suggestedLink && (
        <Input
          mt={3}
          label={"URL"}
          value={url}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUrl(e.target.value)
          }
        />
      )}

      {type === "button" && (
        <Box mt={3}>
          <StyledLabel htmlFor="color">Colour</StyledLabel>
          <ColorInput
            name="color"
            value={color}
            onChange={(color: string) => {
              setColor(color);
            }}
          />
        </Box>
      )}

      <Flex justifyContent={"flex-end"} mt={3}>
        <SecondaryButton mr={3} onClick={() => setIsOpen(false)}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            if (!content) {
              showErrorToast("Please fill in display text.");
            } else if (!url && !suggestedLink) {
              showErrorToast("Please fill in URL.");
            } else if (type === "button" && !color) {
              showErrorToast("Please fill in button colour.");
            } else if (shouldLoadEvents && !eventId) {
              showErrorToast("Please select event.");
            } else {
              onSuccess({
                url,
                content,
                color,
                suggestedLink,
                type,
                eventId,
                releaseId,
              });
              setIsOpen(false);
            }
          }}
        >
          Apply
        </PrimaryButton>
      </Flex>
    </>
  );
};
