import { Box, Flex, SystemProps, Text } from "flicket-ui";
import React from "react";
import styled from "styled-components";

import { Icon } from "~components";

interface TopBarProps extends SystemProps {
  onBack?: () => void;
  title: string;
  subTitle?: string;
}

const Wrapper = styled(Flex)`
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  height: 64px;
  padding: 0;
  justify-content: space-between;
  z-index: ${(p) => p.theme.zIndices.sticky};

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    height: 72px;
  }
`;

const Line = styled.div`
  height: 40px;
  width: 1px;
  background-color: ${(p) => p.theme.colors.N300};
  /* margin: 0 16px; */
`;

const StyledIcon = styled(Icon)`
  padding: 16px;

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    padding: 16px 16px 16px 32px;
  }
`;

export const TopBar: React.FC<TopBarProps> = ({
  children,
  onBack,
  subTitle,
  title,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Flex alignItems="stretch">
        {!!onBack && (
          <>
            <StyledIcon
              icon="chevron-left"
              fontSize={6}
              onClick={onBack}
              type="button"
            />
            <Flex alignItems="center">
              <Line />
            </Flex>
          </>
        )}

        <Flex alignItems="center" px={2}>
          <Box>
            <Text
              fontWeight="heavy"
              lineHeight={"19px" as any}
              fontSize={5}
              color="N800"
            >
              {title}
            </Text>
            {!!subTitle && (
              <Text
                fontSize={2}
                color="S300"
                mt="1/2"
                fontWeight="heavy"
                lineHeight={"13px" as any}
                letterSpacing={"-0.03em" as any}
              >
                {subTitle}
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>

      <Flex alignItems="center" pr={3} pl={2}>
        {children}
      </Flex>
    </Wrapper>
  );
};
